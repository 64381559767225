import React, { useState, useEffect } from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import StatsIllustrationSrc from "../images/stats-illustration.svg";

import {setSEO_title, setSEO_description } from "services/seo";

const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

let wasSetSEO=false;
export default ({ headingText = "CRACKED AI!" , error = "@#!", user=null, aEngine=[]}) => {

  useEffect(() => {
    if(!wasSetSEO) {
      wasSetSEO=true;
      document.title = 'OSAIS - Error!';
      setSEO_title("OSAIS - Cracked Error!");
      setSEO_description("OpenSourceAIs' - looks like you ended on an error page");
    }
  }, []);

  return (
    <AnimationRevealPage>
      <Header 
        user = {user}
      />

      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={StatsIllustrationSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={true}>
            <TextContent>
              <Heading>{headingText}</Heading>
              <Text>
                <h1>There's no page in this place!</h1>
              </Text>

              <Text>
                <p>We could not reached the deep thoughts of our AIs...</p>
                <p>Error code: {error}</p>
              </Text>             
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
      <Footer
        user = {user}
        aEngine = {aEngine}
      />
    </AnimationRevealPage>
  );
};
