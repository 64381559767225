import React, { useState, useEffect, useRef  } from "react";
import tw from "twin.macro";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as ExclamationIcon } from "feather-icons/dist/icons/alert-triangle.svg";
import { ReactComponent as DownIcon } from "feather-icons/dist/icons/chevrons-down.svg";
import { ReactComponent as UpIcon } from "feather-icons/dist/icons/chevrons-up.svg";

import {srv_getAvailableDaemons, srv_getAvailableAIAgentLocationsForEngine, srv_postStartMyAIAgent, srv_patchKeepAliveDaemonContainer} from "services/osais";

import { TYPE_ALERT_CRITICAL, TYPE_ALERT_WARNING, TYPE_ALERT_INFO, } from './myAlert'; 

/* css */
const Feature = tw.li`flex relative items-center`;
const FeatureIconOK = tw(CheckboxIcon)`w-5 h-5 text-green-700 inline mr-4`;
const FeatureIconNOK = tw(ExclamationIcon)`w-5 h-5 text-red-700 inline mr-4`;
const FeatureIconUp = tw(UpIcon)`w-5 h-5 inline pl-1 font-bold `;
const FeatureIconDown = tw(DownIcon)`w-5 h-5 inline pl-1 font-bold`;
const FeatureOK = tw.p`ml-2 font-medium text-green-700 bg-green-100 pl-4 pr-4`;
const FeatureNOK = tw.p`ml-2 font-medium text-red-700 bg-red-200 font-bold pl-4 pr-4`;
const MenuItem = tw.div`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer`;

const BtnContainer= tw.div`relative pr-8`;
const BtnStart = tw.div`ml-4 font-medium text-green-700 bg-green-100 border border-green-400 rounded pl-4 pr-4 cursor-pointer`;

const DAEMON_TYPE_MINE = "mine"
const DAEMON_TYPE_FAVOURITE = "favourite"
const DAEMON_TYPE_PUBLIC = "public"


/* 
*   Class for managing Agent availability (start / check)
*/

export class CStartAgent {
  constructor(data) {
    this.data = data;
    this.aLocation=[]
    this.isStarting = false;
  }

  async async_loadAvailableAILocations() {
    try {
      let data = await srv_getAvailableAIAgentLocationsForEngine(this.data.ai_config.engine);
      return data.data;
    }
    catch(err) {
      return []
    }
  }

  getAgentLocations() {
    return this.aLocation;
  }

  async async_ensureStarted(_reloadAIAgents) {
    let aLoc=await this.async_loadAvailableAILocations();
    if(aLoc.length===0) {
      this.startAgent("any", _reloadAIAgents);
      return {
        isReady: false,
        aLocation: []
      } 
    }

    this.isStarting=false;
    return {
      isReady: true,
      aLocation: aLoc
    }
  }

  async async_keepAlive() { 
    // we should ONLY have 1 container per engine per daemon, so we take first one only
    if (this.data.ai_agent.aLocation.length===0) {
      return false
    }
    let _data=await srv_patchKeepAliveDaemonContainer(this.data.ai_agent.aLocation[0].daemon, this.data.ai_agent.container);
    return _data.data!=null;
  }

  startAgent = (_daemon_type, _reloadAIAgents) => {
    let that=this;
    this.isStarting=true;
    srv_postStartMyAIAgent(this.data.ai_agent.container, this.data.ai_config.port, this.data.ai_config.docker_image, _daemon_type)
    .then(dataStarted => {
      if(dataStarted.data==null) {
        that.isStarting=false;
        throw {
          message: dataStarted.message
        }
      }

      // bubble up update agents 
      if(_reloadAIAgents) {
        _reloadAIAgents()
      }

      let msg="";
      let _status=dataStarted.data.status==="warming_up" ? "warming up " : "starting "
      switch(_daemon_type) {
        case DAEMON_TYPE_MINE:
          msg="Your Daemon is "+_status+this.data.ai_config.name;
          break;
        case DAEMON_TYPE_FAVOURITE:
          msg="One of your favourite Daemons is "+_status+this.data.ai_config.name;
          break;
        default:
        case DAEMON_TYPE_PUBLIC:
          msg="A public Daemon is "+_status+this.data.ai_config.name;
          break;
      }
      this.data.onAlert({
        message: msg,
        type: TYPE_ALERT_INFO,
        timer: 3500
      })  
    })
    .catch(err => {
      this.data.onAlert({
          message: "Could not start "+this.data.ai_config.name + " ("+err.message+")",
          type: TYPE_ALERT_WARNING,
          timer: 3500
        })  
    })
  }
}

/* 
*   UI Widget
*/

export const WidgetAIAvailability = (props) => {

  const [aDaemon, setADaemon] = useState([]);                       // array of all available daemons (for starting AI agent)
  const [aAIAgent, setAAIAgent] = useState([]);                     // array of all available AIAgents (for running Tx)
  const [hasLoadedDaemons, setHasLoadedDaemons] = useState(false);
  const [hasLoadedAgents, setHasLoadedAgents] = useState(false);
  const [hasMyDaemon, setHasMyDaemon] = useState(false);            // is my deamon available?
  const [hasFavDaemon, setHasFavDaemon] = useState(false);          // are Favorite deamons available?
  const [hasPublicDaemon, setHasPublicDaemon] = useState(false);          // are any public deamons available?
  
  const [isRunAIOpen, setIsRunAIOpen] = useState(false);
  const menuRef = useRef(null);  

  const EngineStartAgent = new CStartAgent({
    onAlert: props.onAlert,
    ai_config: props.ai_config,
    ai_agent: props.ai_agent
  });

  const async_loadAvailableDaemons = () => {
    srv_getAvailableDaemons()
      .then(data => {
        setADaemon(data.data);

        // now check if there are my daemons or Fav daemons in there
        data.data.forEach(item => {
          if (item.isMine===true) {
            setHasMyDaemon(true);
          }
          else {
            if (item.isFavourite===true) {
              setHasFavDaemon(true);
            }
            else {
              setHasPublicDaemon(true);
            }
          }
        })
      })
      .catch(err => {})
  }
 
  // load what we need if we need (for an authenticated user)
  useEffect(() => {
    if(props.user && props.ai_agent && props.ai_agent.lastActive_at==null &&  !hasLoadedDaemons) {
      async_loadAvailableDaemons();
      setHasLoadedDaemons(true);

      document.addEventListener("mousedown", handleClickOutside);
    }
    if(props.user && props.ai_agent && props.ai_agent.lastActive_at==null && aDaemon.length>0 && !hasLoadedAgents) {
      setAAIAgent(EngineStartAgent.async_loadAvailableAILocations());
      setHasLoadedAgents(true);
    }
  });

/* 
*   START AI btn + popup menu
*/

  const toggleRunAI = () => {
    if(props.user===null) {
      props.onAlert({
        message: "Only authenticated users can start AIs.",
        type: TYPE_ALERT_WARNING,
        timer: 3500
      })  
    }
    else {
      setIsRunAIOpen(!isRunAIOpen);
    }
  };

  const handleClickOutside = (event) => {
    // Check if the clicked element is outside the popup menu
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsRunAIOpen(false);
    }
  };

  const startAgentWithMyDeamon = () => {
    setIsRunAIOpen(false);
    return EngineStartAgent.startAgent(DAEMON_TYPE_MINE, props.reloadAIAgents)
  }

  const startAgentWithFavDeamon = () => {
    setIsRunAIOpen(false);
    return EngineStartAgent.startAgent(DAEMON_TYPE_FAVOURITE, props.reloadAIAgents)
  }

  const startAgentWithPublicDeamon = () => {
    setIsRunAIOpen(false);
    return EngineStartAgent.startAgent(DAEMON_TYPE_PUBLIC, props.reloadAIAgents)
  }

/* 
*   UI renders
*/

  return (
    <>
      {props.ai_agent? 
        <>
          {props.ai_agent.lastActive_at!=null?
              <Feature key={"active"}>                          
                  <FeatureOK>
                    <FeatureIconOK />
                    AI Agent currently ONLINE at {props.ai_agent.aLocation.length} location {props.ai_agent.aLocation.length>1? "s":""}
                  </FeatureOK>
              </Feature>                
              :
              <Feature key={"inactive"}>
                  
                  <FeatureNOK>
                    <FeatureIconNOK />
                    {props.ai_agent.name} is currently OFFLINE
                  </FeatureNOK>                  

                  <BtnContainer ref={menuRef}>
                    <BtnStart
                      onClick={toggleRunAI}
                    >
                      <span>START AI</span>
                      {isRunAIOpen ?
                        <FeatureIconUp />
                        :
                        <FeatureIconDown />
                      }
                    </BtnStart>
                    
                    {isRunAIOpen && (
                      <div className="absolute ml-4 mt-2 w-full origin-top-right bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">

                          {hasMyDaemon?
                          <MenuItem
                            onClick = {startAgentWithMyDeamon}
                            className=""
                          >
                            On your GPU
                          </MenuItem>
                          :""}

                          {hasFavDaemon?
                          <MenuItem
                            onClick = {startAgentWithFavDeamon}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            On Fav GPUs
                          </MenuItem>
                          :""}

                          {hasPublicDaemon?
                          <MenuItem
                           onClick = {startAgentWithPublicDeamon}
                           className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-200"
                          >
                            On Public Infra
                          </MenuItem>
                          :""}

                        </div>
                      </div>
                    )}

                  </BtnContainer>

                </Feature> 


          }
        </>: ""}
    </>
  
  );
}