import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import {srv_postLogin, srv_getLoggedClient, srv_postAIEngine, srv_getRequestInfo, srv_getRequestPublicInfo, srv_getAIConfig} from "services/osais";

import { TYPE_ALERT_CRITICAL, TYPE_ALERT_WARNING, TYPE_ALERT_INFO, MyAlert } from './myAlert'; 
import { SectionPublicResult } from './aiSectionPublicResult'; 
import { SectionPrivateInput } from './aiSectionInput'; 
import { SectionAIInfo } from './aiSectionInfo'; 
import { SectionWarmup } from './aiSectionWarmup'; 
import { CStartAgent } from './aiAvailability'; 

import { ReactComponent as ExclamationIcon } from "feather-icons/dist/icons/alert-triangle.svg";

/* css */

const Relative = tw.div`relative `;
const Container = tw.div`relative sm:mx-20 my-8 m-0 `;
const Link = tw.a`relative inline xl:text-xl text-gray-600 pl-4 pr-4 pt-1 pb-1`;
const XLLink = tw.a`relative inline text-2xl xl:text-3xl  text-gray-900 mr-4 ml-2`;
const FeatureIconNOK = tw(ExclamationIcon)`w-6 h-6 max-md:w-4 max-md:h-4 text-red-700 absolute left-[4px] top-[4px] mr-1`;
const ExplanationNOK = tw.span`text-red-700 absolute left-[32px] top-[4px] max-md:text-sm max-md:left-[24px] max-md:top-[2px]`;

let paramWasProcessedOnce=false;    // avoid processing params 2 times or more on refresh

// authenticate into OSAIS
let _hasCalledLoginDemo=false;
let _hasCalledLoginUser=false;
let cRespCalls=0;

const _hasVariableInput = (objConfig) => {
  if(!objConfig) {return false}
  for (var i=0; i< objConfig.aParam.length; i++) {
    if(objConfig.aParam[i].value.default==="$random") {
      return true;
    }
  }
  return false;
}

function FormAI(props) {

  // FOR TESTING ONLY
  const [isTest, setIsTest] = useState(false);

  // Who is the logged user?
  const [authToken, setAuthToken] = useState(null);
  const [authTokenDemo, setAuthTokenDemo] = useState(null);

  // AI processing renders
  const [aiEngine, setAiEngine] = useState(props.ai_engine);            // the engine name
  const [isAIAgentAvailable, setIsAIAgentAvailable] = useState(false);
  const [stage, setStage] = useState(0);
  const [expTimeInMs, setExpTimeInMs] = useState(0);
  const [hasRequestedRun, setHasRequestedRun]= useState(false);         // true when user has just requested RUN (revert to false as soon as)
  const [hasFinished, setHasFinished] = useState(false);
  const [hasVariableInput, setHasVariableInput] = useState(_hasVariableInput(props.ai_config));      // true if at least one (default) input has a random value
  const [AIAgentLocation, setAIAgentLocation]= useState(null);          // where is the AI Agent we will use?

  // generic UI
  const [alert, setAlert] = useState(null);

  // ai UI input params
  const [objRequest, setObjRequest] = useState({});                     // params of the request as of when retrieved (can contain more than initially passed)
  const [afnReset, setAFnReset] = useState([]);                         // any fn to call for reset image on the input render
  
  const [uid, setUID] = useState(null);

  // output images
  let _aInit = isTest? [{filename: "http://localhost:3022/uploads/ai/1677706824000.jpg_0232.png"}, {filename: "http://localhost:3022/uploads/ai/1677706876000.jpg_00237.png"}] : []
  const [aImage, setAImage] = useState(_aInit);
  const [costInMs, setCostInMs] = useState(0);

  // is it free to access?
  const [isFree, setIsFree]= useState(props.ai_config.isFree===true);
  const [requiresGPU, setRequiresGPU]= useState(props.ai_config.requiresGPU===false);
  const [isUserAccessGranted, setIsUserAccessGranted]= useState(false);
  const [canUserPay, setCanUserPay]= useState(false);
  const [isAccessFreeForMe, setIsAccessFreeForMe]= useState(false);
  const [isUserSubscribed, setIsUserSubscribed]= useState(false);

  const [EngineStartAgent, setEngineStartAgent]= useState(null);        // to auto start an AI Agent 
  
  const onAlert = (_objAlert) => {
    setAlert(_objAlert);
  }


  useEffect(() => {
    if(props.ai_agent && !EngineStartAgent) {
      let _agent=new CStartAgent({
        onAlert: onAlert,
        ai_config: props.ai_config,
        ai_agent: props.ai_agent
      });
      _agent.async_keepAlive();
      setEngineStartAgent(_agent);
    }
    setIsAIAgentAvailable(props.ai_agent && props.ai_agent.aLocation && props.ai_agent.aLocation.length>0);
  }, [props.ai_agent])

  useEffect(() => {
    // is access free?
    let _isFree=isFree || (requiresGPU===false && isUserSubscribed)
    setIsAccessFreeForMe(_isFree)
    setIsUserAccessGranted(_isFree || (!_isFree && canUserPay>0));
  },[isFree, requiresGPU, isUserSubscribed, canUserPay])

  useEffect(() => {
    // can user pay?
    if(props.user) {
      let _creditsLeft= props.user.credits_paid_left+props.user.credits_granted_left;
      setCanUserPay(_creditsLeft>0);

      let _dateSubscribedUntil=props.user.subscribed_until? new Date(props.user.subscribed_until): null;
      let now = new Date();
      let _isSubscribed=_dateSubscribedUntil && _dateSubscribedUntil>now;
      setIsUserSubscribed(_isSubscribed);
    }
    else {
      if(props.user===null) {
        setAlert({
          message: "You are using a demo account. Login to avoid limitations...",
          type: TYPE_ALERT_WARNING,
          timer: 3500
        })  
      }
    }
  },[props.user])


/* 
 *    Init client for processing requests (demo client, or User default client)
 */  

    const async_authenticateDemoClient = ( )=> {
      srv_postLogin({})
      .then(async function(data){
        if(data && data.data && data.data.authToken) {
            setAuthTokenDemo(data.data.authToken);
            _async_initClient(data, data.data.authToken);  
        }
      })  
      .catch(function(err){
        // we re not in a good shape
      })
    }

    const async_authenticateUserDefaultClient = ( )=> {
      srv_getLoggedClient()
      .then(async function(data){
        if(data && data.data && data.data.authToken) {
            setAuthToken(data.data.authToken);
            _async_initClient(data, data.data.authToken);  
        }
      })  
      .catch(function(err){
      })
    }

    // log the user?
    useEffect(() => {
      if(props.user && !authToken && !_hasCalledLoginUser) {
        _hasCalledLoginUser=true;
        async_authenticateUserDefaultClient();
      }
    }, [props.user])

    // init what we can as a Demo User (fallback scenario in all cases)
    if(!_hasCalledLoginDemo) {
      _hasCalledLoginDemo=true;
      async_authenticateDemoClient();
    }

    const _async_initClient= async(data, _authToken) => {
      if(data && data.data && _authToken) {

        // are we getting a specific request?
        if(!paramWasProcessedOnce) {
          const pathnameParts = window.location.href.split("/");
          const lastPathnamePart = pathnameParts[pathnameParts.length-1]!==""? pathnameParts[pathnameParts.length-1] : pathnameParts[pathnameParts.length-2];
          const _request=parseInt(lastPathnamePart);

          if(_request && _request!==0) {
            // we want a replay... go to it
            if(!uid) {
              paramWasProcessedOnce=true;
              setUID(_request);

              // show what we got
              async_refreshUI(_request, _authToken);      
            }
          }  
        }
      }
    }

/* 
 *    utilities   
 */  

  const getObjectImageSrc = (_state) => {
    let _aTmp=_state.config;
    let iU=_aTmp.findIndex(function (x) {return (x.in==="url_upload")});
    if(iU!==-1 && _aTmp[iU]["value"]) {
      return _aTmp[iU]["value"]["current"];
    }
    return null;
  }
 
/* 
 *    callback fcts   
 */  

  const onPreReset = (_fnReset) => {
    // all those params we have to reset down the UI
    if(!_fnReset) {return}
    let _aTemp = afnReset.slice();
    if(!_aTemp.includes(_fnReset)) {
      _aTemp.push(_fnReset);
    }
    setAFnReset(_aTemp);
  }

  const onReset = (_engine) => {
    // make sure we have an engine
    if(_engine) {
      setAiEngine(_engine);
    }

    // all those params we have to reset down the UI
    if(afnReset) {
      afnReset.forEach(_fnReset => {
        _fnReset();
      }) 
    }

    // all those params we can reset from here
    setAImage([]);
    setStage(0);
    setExpTimeInMs(null);
    setCostInMs(0);
    setUID(null);
    setObjRequest({});
    setHasFinished(false);
    cRespCalls=0;
  }

  const onShare = () => {
    if(uid) {
      window.open(window.location.origin+"/WorldOfAIs/public/request/"+uid);
    }
  }

  const onRegenerate = () => {
    // we regenerate with same params
    if(objRequest) {
      if(!objRequest.engine) {
        objRequest.engine=aiEngine;
      }
      async_callAI(objRequest);
    }
  }

  const async_onSubmit = async function(_state, bIsRetry) {

    setHasRequestedRun(true);

    // authenticated user? => ensure we have a AI Agent available
    if(props.user && props.user.username) {
      let objReady= await EngineStartAgent.async_ensureStarted(props.reloadAIAgents);
      if(!objReady.isReady) {
        // not yet even started
        setAlert({
          message:"Looking for a machine to start an AI Agent...",
          type: TYPE_ALERT_INFO,
          timer: 3500
        })  
      }

      if(objReady.aLocation && objReady.aLocation.length>0 && objReady.aLocation[0].status!=="ready") {
        let _msg=objReady.aLocation[0].status==="initializing"? "AI Agent is initializing..." : objReady.aLocation[0].status==="initialized"? "AI Agent is now warming up..." : null;
        if(_msg) {
          setAlert({
            message: _msg,
            type: TYPE_ALERT_INFO,
            timer: 3500
          })                    
        }
      }

      // loop until ready
      if(!objReady.isReady || objReady.aLocation[0].status!=="ready") {
        // we need to wait until the AI is up and running
        return new Promise((resolve, reject) => {
          setTimeout(async() => {
            let _data=await async_onSubmit(_state, true)
            resolve(_data);
          }, 5000);
        });
      }

      // only display AI readiness msg if AI was just started
      if(bIsRetry) {
        setAlert({
          message: "AI Agent is ready to process",
          type: TYPE_ALERT_INFO,
          timer: 3500
        })         
      }

      setAIAgentLocation(objReady.aLocation.length>0? objReady.aLocation.length[0] : null);
      setIsAIAgentAvailable(true);
    }

    // todo : pass all params...
    let objParam={};
    let aVar=_state.config;
    aVar.forEach(item => {
      if(item.value && item.value.current!==null) {
        objParam[item.in]=item.value.current;
      }
      else {
        // F*#@!!  JS is sometimes saying 0 is null or null is 0...
        if(item.type==="int") {
          objParam[item.in]=0;
        }
      }
    });

    // add image? 
    let _src=getObjectImageSrc(_state);
    if(_src) {
      objParam.url_upload=_src;
    }

    if(!objParam.engine) {
      objParam.engine=aiEngine;
    }
    await async_callAI(objParam);
  }

  const async_callAI = async function(objParam) {
    let _authToken =  authToken && isUserAccessGranted? authToken : authTokenDemo;
    try {
      if(_authToken) {

        // call AI
        
        let dataReq=await srv_postAIEngine(objParam.engine, objParam, _authToken);
        setHasRequestedRun(false);

        if(dataReq && dataReq.data && dataReq.data.uid) {
          setUID(dataReq.data.uid);

          // refresh AI stage for UI
          setStage(0);
          setExpTimeInMs(null);
          setCostInMs(0);
          setHasFinished(false);
          cRespCalls=0;
          setAImage([]);

          // first call only after 1 sec?
          setTimeout(function(){
            async_refreshUI(dataReq.data.uid, _authToken);
          }.bind(this), 1000);  
        }
      }
      else {
        // show an error
        setHasRequestedRun(false);
        setAlert({
          message: "Could not access OSAIS AIs",
          type: TYPE_ALERT_CRITICAL,
          timer: 4500
        });
      }
    }
    catch(err) {
      throw err
    }
  }
  
/* 
 *    UI
 */  


  const async_refreshUI = async function(_uid, _authToken) {

    // get info from backlog ; was it processed?
    try {
        // stop refresh if reset was called
//        if (_uid !== uid) {
//          return;
//        }

        const _showWhatWeGot = function (_dataReq, _hasFinished){
          // display the images we have
          setCostInMs(_dataReq.data.cost_in_ms);
          setAImage(_dataReq.data.response);
          setObjRequest(_dataReq.data.params);
          setHasFinished(_hasFinished);
          // async_loadConfig(_dataReq.data.ai_engine);
        }

        // public or private call to osais?
        let _srvCall=srv_getRequestInfo;
        if(props.isPublic) {
          _srvCall=srv_getRequestPublicInfo;
        }

        // still on same uid... we ping
        let dataReq= await _srvCall({
          uid: _uid,
        }, _authToken);

        if(dataReq && dataReq.data) {
          setStage(dataReq.data.stage); 
          if(dataReq.data.stage>=2) {      // AI_STARTED
            setExpTimeInMs(dataReq.data.est_cost_in_ms);
          }

          // refresh until finished
          let _qtyExpect=dataReq.data.qty? dataReq.data.qty : 1;
          let _hasFinished=(
              dataReq.data.stage<0 ||
              dataReq.data.stage===6 || 
              (dataReq.data.stage===5 && dataReq.data.response && _qtyExpect<=dataReq.data.response.length));
          
          // todo : find a good way to stop so many calls
          if(!_hasFinished) {
            cRespCalls++;
            if(cRespCalls>40) {
              return
            }

            // determine the frequency of calls (not faster then every 600ms, not slower than every 2sec)
            let _expDelay=dataReq.data.est_cost_in_ms ? (dataReq.data.est_cost_in_ms+500) : 2500;
            let _delay= dataReq.data.stage===5? 600 : Math.max(800, Math.min(4000, _expDelay/(1+(cRespCalls/2))));    // if we are on stage 5, we check asap, otherwise, we check pro rata expected time 

            setTimeout(function(){
              async_refreshUI(_uid, _authToken);
            }.bind(this), _delay);  
            setObjRequest({});
          }

          if(dataReq.data.stage>=4) {
            _showWhatWeGot(dataReq, _hasFinished);
          }
        }

        // request not found or other error
        else {
          setHasFinished(true);
          setStage(-10)
          setExpTimeInMs(null);
        }
      }
      catch(err) {
        throw err
      }
  }
  
/* 
*   UI 
*/


  return (
    <Container>

      {alert? 
        <MyAlert 
          message={alert.message}
          type={alert.type}
          timer={alert.timer}
        />
        :""
      }

      {props.ai_config? 
      <>
        <XLLink >{props.ai_config.name}</XLLink>
        <Link href={"/WorldOfAIs/ai/"+props.ai_config.engine} className={props.panel=="run" ? "ai_panel selected" : "ai_panel unselected"}>Run</Link>
        <Link href={"/WorldOfAIs/ai/"+props.ai_config.engine+"/info"} className={props.panel=="info" ? "ai_panel selected" : "ai_panel unselected"}>Info</Link>
      </>
      : ""}

    {
      props.panel=="run"?
      <Relative>
        {props.ai_agent && !isAIAgentAvailable?  
        <>
          <FeatureIconNOK />
          <ExplanationNOK>AI offline - extra warmup time {props.ai_agent.expected_warmup_in_sec} sec</ExplanationNOK>
        </>
        :""}
        {uid===null  && !hasRequestedRun? 
          <SectionPrivateInput 
            isVisible = {true}
            isMobile = {props.isMobile}
            user = {props.user}
            authToken = {authToken? authToken : authTokenDemo}
            ai_engine = {aiEngine}
            ai_config = {props.ai_config}
            ai_agent = {props.ai_agent}
            isUserAccessGranted = {isUserAccessGranted}
            isAIAgentAvailable = {isAIAgentAvailable}
            onSubmit = {async_onSubmit}
            onPreReset = {onPreReset}
            onAlert = {onAlert}
          />

      : ""}
      
      { hasRequestedRun && AIAgentLocation==null ?
        <SectionWarmup 
          isVisible = {true}
          isMobile = {props.isMobile}
          user = {props.user}
          authToken = {authToken? authToken : authTokenDemo}
          ai_engine = {aiEngine}
          ai_config = {props.ai_config}
          ai_agent = {props.ai_agent}
          onAlert = {onAlert}
        />
      :""}

      {uid!==null ? 
        <SectionPublicResult 
            user = {props.user}
            isMobile = {props.isMobile}
            backButtonText = "Try again"
            shareButtonText = "Share"
            onClickBack={onReset}
            onClickShare={onShare}
            onClickGenerate={onRegenerate}
            ai_config = {props.ai_config}
            stage = {stage}
            expTimeInMs = {500+expTimeInMs}
            aImage = {aImage}
            hasFinished = {hasFinished}
            hasVariableInput = {hasVariableInput}
            request = {objRequest}
            isPublic = {props.isPublic}
            cost_in_ms = {costInMs}
          />
      : ""}
      </Relative>
      :
      <SectionAIInfo 
        isVisible = {true}
        isMobile = {props.isMobile}
        user = {props.user}
        authToken = {authToken? authToken : authTokenDemo}
        ai_engine = {aiEngine}
        ai_config = {props.ai_config}
        ai_agent = {props.ai_agent}
        isUserAccessGranted = {isUserAccessGranted}
        onAlert = {onAlert}
        reloadAIAgents = {props.reloadAIAgents}
      />

    }
      
  </Container>

  );

}

export default FormAI;