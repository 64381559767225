import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "../../images/email-illustration.svg";
import {srv_postContactUs} from "services/osais";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const DivForm = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const DivMessage = tw.div`mt-16 mb-8 font-bold hidden`;
const DivMessageError = tw.div`mt-16 mb-8 font-bold hidden text-red-500`;

const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 hidden`
const SubmitButtonDisabled = tw(PrimaryButtonBase)`inline-block mt-8 opacity-50 cursor-not-allowed`

const validateEmail =(email) => {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
}

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "We appreciate the time you take to share your feedback. We do not store your email in a database, you will not be contacted from us other than for the purpose of answering your message. ",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const onClick = (evt) => {

    let eltForm=document.getElementById("id_formContact");
    let eltFormMsgSent=document.getElementById("id_messageSent");
    let eltFormMsgNotSent=document.getElementById("id_messageNotSent");
    let eltEmail=document.getElementById("id_formContact_email");
    let eltName=document.getElementById("id_formContact_name");
    let eltSubject=document.getElementById("id_formContact_subject");
    let eltMessage=document.getElementById("id_formContact_message");
    srv_postContactUs({
      message: eltMessage.value,
      subject: eltSubject.value,
      name: eltName.value,
      email: eltEmail.value      
    })
    .then (data => {
      if(data && data.data) {
        eltForm.style.display="none";
        eltFormMsgSent.style.display="block";  
      }
      else {
        eltForm.style.display="none";
        eltFormMsgNotSent.style.display="block";  
      }
    })
    .catch (err => {
      eltForm.style.display="none";
      eltFormMsgNotSent.style.display="block";
    })    
    return false;
  }

  const enableSubmit = () => {
    let isEnabled=true;
    let elt=document.getElementById("id_formContact_name");
    isEnabled=isEnabled && (elt.value.length>1);
  
    elt=document.getElementById("id_formContact_email");
    isEnabled=isEnabled && (validateEmail(elt.value));
  
    elt=document.getElementById("id_formContact_subject");
    isEnabled=isEnabled && (elt.value.length>4);
  
    elt=document.getElementById("id_formContact_message");
    isEnabled=isEnabled && (elt.value.length>5);
  
    let eltButton=document.getElementById("id_formContact_submit");
    let eltButtonDisabled=document.getElementById("id_formContact_submitDisabled");
    if (isEnabled) {
      eltButton.style.display="block";
      eltButtonDisabled.style.display="none";
    }
    else {
      eltButton.style.display="none";
      eltButtonDisabled.style.display="block";
    }
  } 
  
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <DivForm id="id_formContact" >
              <Input id="id_formContact_email" type="email" name="email" placeholder="Your Email Address" onChange={enableSubmit} />
              <Input id="id_formContact_name" type="text" name="name" placeholder="Full Name (at least 2 chars)" onChange={enableSubmit}/>
              <Input id="id_formContact_subject" type="text" name="subject" placeholder="Subject (at least 5 chars)" onChange={enableSubmit}/>
              <Textarea id="id_formContact_message" name="message" placeholder="Your Message Here (at least 6 chars)" onChange={enableSubmit} />
              
              <SubmitButtonDisabled
                id="id_formContact_submitDisabled"
              >
                {submitButtonText}
              </SubmitButtonDisabled>

              <SubmitButton 
                id="id_formContact_submit"
                type="submit" 
                onClick={onClick}
              >{submitButtonText}</SubmitButton>
            </DivForm>

            <DivMessage id="id_messageSent">
              Your message was sent, we will be in touch soon!
            </DivMessage>
            <DivMessageError id="id_messageNotSent">
              Wow! Your message was NOT sent! 
              <br /><br />
              Maybe send a direct email to: info [at] opensourceais [dot] com 
            </DivMessageError>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
