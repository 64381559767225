import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import 'tw-elements';

import { ReactComponent as CheckCircleIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as XCircleIcon } from "feather-icons/dist/icons/x-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "feather-icons/dist/icons/alert-triangle.svg";

// CSS
const AlertGreen=styled.div `${tw`  rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/75 font-medium text-[#fefefe] mb-4 rounded-lg py-4 px-4 text-base  ` }`; 
const AlertOrange=styled.div `${tw`  rounded-none border-l-4 border-[#dd6b20] bg-[#dd6b20]/75 font-medium text-[#fefefe] mb-4 rounded-lg py-4 px-4 text-base  ` }`; 
const AlertRed=styled.div `${tw`  rounded-none border-l-4 border-[#ff7165] bg-[#ff7165]/75 font-medium text-[#fefefe] mb-4 rounded-lg py-4 px-4 text-base  ` }`; 
const AlertSpan=styled.div `${tw` inline` }`;

const DEFAULT_TIMER=3800;
export const TYPE_ALERT_CRITICAL="critical";
export const TYPE_ALERT_WARNING="warning";
export const TYPE_ALERT_INFO="info";

const gaShow = [];

export const MyAlert = (props) => {
  const [obj1, setObj1] = useState(null);
  const [obj2, setObj2] = useState(null);
  const [obj3, setObj3] = useState(null);

  useEffect(() => {
      _pumpMessage ({
        message: props.message,
        type: props.type,
        delay: props.delay? props.delay: DEFAULT_TIMER
      });    
  },[props.message])

  const refreshUI = () => {
    if(gaShow.length>2) {
      setObj1(gaShow[0]);
      setObj2(gaShow[1]);
      setObj3(gaShow[2]);
      return;
    }

    if(gaShow.length>1) {
      setObj1(gaShow[0]);
      setObj2(gaShow[1]);
      setObj3(null);
      return;
    }

    if(gaShow.length>0) {
      setObj1(gaShow[0]);
      setObj2(null);
      setObj3(null);
      return;
    }
    
    setObj1(null);
    setObj2(null);
    setObj3(null);
  }
  
  const _findMessageIndexByTimer = (_timer) => {
    let i=gaShow.findIndex(function (x) {return (_timer===x.timer)});
    return i
  }

  const _findMessageIndexByMessage = (_msg) => {
    let i=gaShow.findIndex(function (x) {return (_msg===x.message)});
    return i
  }

  const closeMessage = (_timerStart) => {
    let i=_findMessageIndexByTimer(_timerStart);
    if(i>=0) {
      gaShow.splice(i, 1);
      refreshUI();
    }
  }

  // we process a new message
  const _pumpMessage = (objMessage) => {
    
    // we don t do anything if we are already processing it
    let i=_findMessageIndexByMessage(objMessage.message);
    if(i>=0) {
      return;
    }
    
    // start countdown for how long we show this message 
    let _timerStart=setTimeout(() => {

      // update the react array      
      let i=_findMessageIndexByTimer(_timerStart);
      if(i>=0) {

        // now retract message 
        let eltSlide=document.getElementById(gaShow[i].timer);
        if(eltSlide) {
          eltSlide.style.right="-190%";
          eltSlide.style.transition="1s";      
        }

        // now remove from list of messages
        setTimeout(() => {
          closeMessage(_timerStart)
        }, 1000);  
      }
    
    }, objMessage.delay);
    
    gaShow.push({
      timer: _timerStart,
      hasReachedEnd : false,
      message: objMessage.message,
      type: objMessage.type,
      delay: objMessage.delay * (1+gaShow.length),
      mt: gaShow.length        // some margin for showing multiple messages 
    });

    refreshUI();
  }

  const _renderIcon = (_type) => {
    if(_type==="critical") {
      return (
        <AlertTriangleIcon 
          className="inline mr-2"
        /> 
      )
    }
    return (
      <CheckCircleIcon 
        className="inline mr-2"
      /> 
    )
}

  const _renderText = (_text) => {
    return (
      <AlertSpan
        className="inline mr-2"
      > 
        {_text} 
      </AlertSpan>
    )
  }

  const _renderClose = (_timer) => {
    return (      
      <XCircleIcon 
        className="inline "
        onClick = {() => { closeMessage(_timer) }}
      />
    )
  }

  const renderAlert = (_item) => {
    return (
      <div 
        index = {_item.timer}
        className="slide-wrapper" 
      >
        {props.type===TYPE_ALERT_CRITICAL? 
          <AlertRed
            className = {"slide-message slide-message-pos-"+_item.mt}
            id={_item.timer}
            role="alert"
          >
            {_renderIcon(_item.type)}
            {_renderText(_item.message)}
            {_renderClose(_item.timer)}
          </AlertRed>   
        :
        props.type===TYPE_ALERT_INFO? 
          <AlertGreen
            className = {"slide-message slide-message-pos-"+_item.mt}
            id={_item.timer}
            role="alert"
          >
            {_renderIcon(_item.type)}
            {_renderText(_item.message)}
            {_renderClose(_item.timer)}
          </AlertGreen> 
        : 
          <AlertOrange
            className = {"slide-message slide-message-pos-"+_item.mt}
            id={_item.timer}
            role="alert"
          >
            {_renderIcon(_item.type)}
            {_renderText(_item.message)}
            {_renderClose(_item.timer)}
          </AlertOrange> 
        }
      </div>
    )
  }

  return (
    <>
      {obj1?  renderAlert(obj1) : ""}
      {obj2?  renderAlert(obj2) : ""}
      {obj3?  renderAlert(obj3) : ""}
    </>
  );
};
