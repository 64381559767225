import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";

// CSS
const PictureSelContainer=styled.div `${tw` absolute right-[-24px] top-[72px] z-50 `}`;

const Card=styled.div `${tw` grid w-80 min-h-[140px] w-full place-items-center overflow-x-scroll p-1 lg:overflow-visible bg-gray-100  rounded-xl ` }`;
const List=styled.div `${tw`relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-lg w-80 `}`;
const ListItem=styled.div `${tw` flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-500 hover:bg-opacity-25 focus:bg-gray-500 focus:bg-opacity-25 active:bg-gray-500 active:bg-opacity-25 hover:text-gray-900 focus:text-gray-900 active:text-gray-900 outline-none `}`;
const ListItemPrefix=styled.div `${tw`grid place-items-center mr-4`}`;
const Avatar=styled.img `${tw`inline-block relative object-cover object-center !rounded-full w-12 h-12 rounded-lg`}`;
const TypoH6=styled.div `${tw`block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900 cursor-pointer `}`;
const TypoSmall=styled.div `${tw` block antialiased font-sans text-sm leading-normal text-gray-700 font-normal cursor-pointer `}`;

export const UiFileSelectProfile = (props) => {


    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isListOpen, setIsListOpen] = useState(false);   

/* 
*      
*/

  const shallOpen = () => {
    return props.isVisible && props.user && props.user.aPicture && props.user.aPicture.length>0
  }

  const onSelectProfile = (iSel, _s3) => {
    if(props.onSelect) {
      let _url=getUrlFromS3(_s3);
      props.onSelect(iSel, _s3, _url)
    }
    
  }

  const getUrlFromS3 = (_s3) => {
    if(!_s3) {
      return null
    }

    return _s3.replace("s3://osais/input/", "/uploads/client/") 
  }

/*
 *    Renders
 */

  const renderItem = (item, i) => {
    return (
      <ListItem
        key = {i}
        data={item}
        onClick = {() => {onSelectProfile(i, item)}}
      >
        <ListItemPrefix>
          <Avatar  
            alt={props.user.firstName? props.user.firstName : ""} 
            src={getUrlFromS3(item)} 
          />
        </ListItemPrefix>
        <div>
          <TypoH6 color="gray">
            Picture #{(1+i)}
          </TypoH6>
          <TypoSmall color="gray" className="font-normal">
            No tag
          </TypoSmall>
        </div>
      </ListItem>
    )
  }
  const renderListOfProfile = () => {
    return (
      <Card>
        <List>
          {props.user && props.user.aPicture && props.user.aPicture.length>0 ? 
            props.user.aPicture.map((item, i) => (
              renderItem(item, i)
            ))
          : 
              ""
          }

        </List>
      </Card>
    );
  }

  return (
    <PictureSelContainer
      className= {shallOpen() ? "": "hidden"}
    >
      {renderListOfProfile()}

    </PictureSelContainer>
  );
};
