
import {API_PUBLICROUTE, srv_getRoute} from "./base";

/*
 *    Blog
 */

// call this to get User's private info (and also to check the user is logged in)
const srv_getArticles = async() => {
  return srv_getRoute(API_PUBLICROUTE+'blog/articles', null);
}

const srv_getAuthors = async() => {
  return srv_getRoute(API_PUBLICROUTE+'blog/authors', null);
}

const srv_getCategories = async(_id) => {
  return srv_getRoute(API_PUBLICROUTE+'blog/categories', null);
}

const srv_getArticleHeadlineImage = async(_id) => {
  return srv_getRoute(API_PUBLICROUTE+'blog/article/'+_id+"/image", null);
}

export { 
  srv_getArticles,
  srv_getCategories,
  srv_getAuthors,
  srv_getArticleHeadlineImage
}