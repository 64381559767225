import React from "react";

export const MyRubik = (props) => {

  const renderRow = (_pre, _face, _prerow, _row)=> {
    return (
      <div className={_pre+"-face "+_face+" "+_prerow+_row} >
        <div className={_row+"-one"}></div>
        <div className={_row+"-two"}></div>
        <div className={_row+"-three"}></div>
      </div>
    )
  }

  return (
    <div className="rubiks-container">
      <div className="rubiks-cube">
        <div className="top-clone">
          {renderRow ("column", "top", "clone-", "left")}
          {renderRow ("column", "top", "clone-", "middle")}
          {renderRow ("column", "top", "clone-", "right")}
        </div>
        <div className="top-row">
          {renderRow ("row", "", "", "front")}
          {renderRow ("row", "", "",  "left")}
          {renderRow ("row", "", "",  "back")}
          {renderRow ("row", "", "",  "right")}
        </div>
        <div className="middle-row">
          {renderRow ("row", "", "", "front")}
          {renderRow ("row", "", "",  "left")}
          {renderRow ("row", "", "",  "back")}
          {renderRow ("row", "", "",  "right")}
        </div>
        <div className="bottom-row">
          {renderRow ("row", "", "",  "right")}
          {renderRow ("row", "", "",  "left")}
        </div>
        <div className="left-column">
          {renderRow ("column", "", "",  "front")}
          {renderRow ("column", "", "",  "back")}
          {renderRow ("column", "", "",  "bottom")}
        </div>
        <div className="middle-column">
          {renderRow ("column", "", "",  "front")}
          {renderRow ("column", "", "",  "top")}
          {renderRow ("column", "", "",  "back")}
          {renderRow ("column", "", "",  "bottom")}
        </div>
        <div className="right-column">
          {renderRow ("column", "", "",  "front")}
          {renderRow ("column", "", "",  "back")}
          {renderRow ("column", "", "",  "bottom")}
        </div>
      </div>
    </div>
  );
};
