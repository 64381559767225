import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { srv_getArticleHeadlineImage } from "services/sanity";

import {setSEO_title, setSEO_description } from "services/seo";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

let wasSetSEO=false;

export default (props) => {
  const [visible, setVisible] = useState(7);
  const [aArticle, setAArticle] = useState([]);

  useEffect(() => {
    if(!wasSetSEO) {
      wasSetSEO=true;
      document.title = 'OSAIS - Blog';
      setSEO_title("Open Source AIs Blog");
      setSEO_description("Access all value articles from OpenSourceAIs");
    }
  }, []);

  useEffect(() => {
    if(aArticle.length===0 && props.aArticle.length>0) {
      let wasFeatured=false;
      let aPost=[];
      props.aArticle.map((item) => {
        if(item.slug) {
          if(!wasFeatured) {
            item.featured=true;
            wasFeatured=true;
          }
          let _obj=getPlaceholderPost(item);
          aPost.push(_obj);
        }
      });
      props.aAIAgent.map((item) => {
        aPost.push(getPlaceholderAI(item))
      });
      setAArticle(aPost);
    }

  }, [props.aArticle]);
  
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  
  const getPlaceholderAI = (objParam) => ({
    headlineImage: "/assets/preview_"+objParam.engine+".jpeg",
    category: "AI",
    date: "August 9, 2023",
    title: "Have you visited AI "+objParam.name+"?",
    description: objParam.description,
    url: objParam.url
  });

  const getFormattedDate = (_date) => {
    let _tmp=new Date(_date);
    return _tmp.toLocaleString('default', { month: 'long' }) + " "+_tmp.getUTCDate()+", "+_tmp.getUTCFullYear();
  } 

  const getCategory = (aCat) => {
    if(props.aCategory && aCat && aCat.length>0) {
      let i=props.aCategory.findIndex(function (x) {return (x._id===aCat[0]._ref)});
      if(i!==-1) {
        return props.aCategory[i].title;
      }  
    }
    return "Blog post"    
  }  

  const getPlaceholderPost = (objParam) => ({
    headlineImage: objParam.headlineImage? objParam.headlineImage : null,
    headlineVideo: objParam.headlineVideo? objParam.headlineVideo : null,
    category: getCategory(objParam.categories),
    date: getFormattedDate(objParam._updatedAt),
    title: objParam.title,
    description: objParam.description,
    url: "/article/"+objParam.slug.current
  });

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Blog Posts</Heading>
          </HeadingRow>
          <Posts>
            {aArticle.slice(0, visible).map((post, index) => (
              <PostContainer 
                key={index} 
                featured={post.featured}
                className="blogIndex"
              >
                <Post className="group" as="a" href={post.url}>
                  {post.headlineVideo? 
                      <iframe src={post.headlineVideo} frameborder="0" className="headlineVideo" /> 
                    :
                    post.headlineImage? 
                      <img src={post.headlineImage} className="headlineImage" />
                    :
                    <img src={"/assets/preview_cnet.jpeg"} className="headlineImage" />
                    
                  }
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < aArticle.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
