import React, { useState, useEffect, Fragment } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactCrop from 'react-image-crop';
import tw from "twin.macro";
import styled from "styled-components";

import {util_setProps, util_adjustImagePos, util_onImgCropZoomIn, util_onImgCropZoomOut, util_onImgCropChange, util_onImgCropResetScale, util_async_getCroppedImage} from "./dialog-zoom-utils";

import { ReactComponent as ZoomInIcon } from "feather-icons/dist/icons/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "feather-icons/dist/icons/zoom-out.svg";
import { ReactComponent as CheckCircleIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as MaximizeIcon } from "feather-icons/dist/icons/maximize.svg";

import 'react-image-crop/dist/ReactCrop.css';
import "./dialogZoom.css";

const Button=styled.button `${tw` font-sans font-bold uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-red-500 hover:bg-red-500 hover:text-white active:bg-red-500 mr-1`}`;
const TBButton=styled.button `${tw` font-sans font-bold uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-4 mb-3 ml-2 text-white rounded-lg bg-blue-500 text-white shadow-md shadow-blue-500 hover:shadow-lg hover:shadow-blue-500 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none  `}`;
const TBButtonGreen=styled.button `${tw` font-sans font-bold uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-4 mb-3 ml-2 text-white rounded-lg bg-green-500 text-white shadow-md shadow-green-500 hover:shadow-lg hover:shadow-green-500 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none  `}`;
const DialogBlur=styled.div `${tw` fixed z-50 top-0 left-0 bg-gray-900 w-full h-full `}`;
const Dialog=styled.div `${tw` relative mt-[4%] bg-white m-4 rounded-lg shadow-2xl  antialiased font-sans text-base font-light leading-relaxed overflow-hidden mx-auto my-auto w-3/5 min-w-[85%] max-w-[85%] md:min-w-[70%] md:max-w-[70%] lg:min-w-[700px] lg:max-w-[700px]`}`;
const DialogHeader=styled.div `${tw` flex items-center shrink-0 p-4 font-bold antialiased font-sans text-2xl font-semibold leading-snug `}`;
const DialogBody=styled.div `${tw` relative flex-auto p-4  antialiased font-sans text-base font-light leading-relaxed border-t  border-b  block `}`;
const DialogFooter=styled.div `${tw` flex items-center justify-end shrink-0 flex-wrap p-4  `}`;


/* need to check on windows dimension changes to keep aspect ratio when cropping */
function useWindowDimensions() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return {
    width,
    height,
  }
}

/* Here we start */
export function DialogZoom(props) {
  const { width, height } = useWindowDimensions();
  const [isVisible, setIsVisible] = useState(false);
  const [hasZoom, setHasZoom] = useState(props.hasZoom? props.hasZoom===true : false);   
  const [hasCrop, setHasCrop] = useState(props.hasCrop? props.hasCrop===true : false);   
  const [crop, setCrop] = useState({
    unit: 'px',
    x: 0,
    y:0,
    aspect: props.aspect,
    width: props.cropWidth,
    height: props.cropHeight
  });   

  useEffect(() => {
    setIsVisible(props.isVisible===true);

    // keep zoom utilities in synch with new requests
    util_setProps(props);
  },[width, height, props.isVisible]);

  const async_onImgCropComp = async( ) => {
    // we do nothing here (replaced by "validate button")    
    return;
  }

  // change crop according to UI resize
  const onImgCropChange  = (_crop) => {
    setCrop(util_onImgCropChange(_crop));
  }

  // change crop according to UI reset
  const onImgCropResetScale = () => {
    setCrop(util_onImgCropResetScale("imageCrop"))
  }

  // user accepts new crop
  const onImgCropValidate = async () => {
    let _filenameNoExt=props.filename.replace(".jpg","").replace(".jpeg","").replace(".png","");
    let _file=await util_async_getCroppedImage("imageCrop", _filenameNoExt+'-resized.jpg');
    if(_file) {
      props.onCrop(_file);
    }
  }

  // user rejects change
  const onClose = ( )=> {
    if(props.onClose) {
      props.onClose();
    }
  };

  const addZoomButtons = (zoomIn, zoomOut, resetTransform) => {
    return (
      <div className="toolbar">
        <Button 
          onClick={() => zoomIn()}
        >
          <ZoomInIcon />
        </Button>

        <Button 
          onClick={()=> zoomOut()}
        >
          <ZoomOutIcon />
        </Button>
        
        <Button 
          onClick={() => resetTransform()}
        >
          <span><i className="fas fa-expand" /></span>
        </Button>
      </div>
    );
  }

  const addCropButtons = () => {
    return (
      <div className="toolbar">
        <TBButton 
          onClick={()=> {util_onImgCropZoomOut("imageCrop")} }
          title="Zoom out"
        >
          <ZoomOutIcon />
        </TBButton>
        
        <TBButton
          onClick={()=> {util_onImgCropZoomIn("imageCrop")}}
          title="Zoom in"
        >
          <ZoomInIcon />
        </TBButton>
        
        <TBButton
          onClick={() => {onImgCropResetScale()}}
          title="Auto resize"
        >
          <MaximizeIcon />
        </TBButton>
        <div className="space">
        </div>

        <TBButtonGreen
          title="Validate"
          onClick={() => {onImgCropValidate()}}
        >
              <CheckCircleIcon 
              />
        </TBButtonGreen>

      </div>
    );
  }
  return (
      <DialogBlur 
        className={isVisible? "" : "hidden"}
      >
        <div 
          className="grid place-items-center fixed w-screen h-screen bg-gray-500 bg-opacity-60 backdrop-blur-sm"
        >
        <Dialog 
          handler={onClose}
        >
          <DialogHeader>Crop and resize</DialogHeader>

          <DialogBody divider className="block ">
            <div>
                <div className="zoom-container">
                  <div id="imageContainer" className="image-container">
                    {hasZoom ? 
                    <TransformWrapper>

                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <div className="image-wrapper hasZoom">
                            <TransformComponent>
                              <img
                                id="imageZoom"
                                className="image"
                                src={props.image}
                              />
                            </TransformComponent>
                          </div>
                          <div className="progressContainer">
                            {addZoomButtons(zoomIn, zoomOut, resetTransform)}
                          </div>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                    :

                    hasCrop? 
                      <>
                          <div className="image-wrapper">
                            <ReactCrop
                              crop={crop}
                              onChange={onImgCropChange}
                              onComplete={async_onImgCropComp}
                            >
                              <img
                                id="imageCrop"
                                className="image"
                                src={isVisible? props.image: null}
                                onLoad = {() => {
                                  onImgCropResetScale();
                                }}
                              />
                            </ReactCrop>
                          </div>
                          <div className="progressContainer">
                            {addCropButtons()}
                          </div>

                          {util_adjustImagePos("imageContainer")}
                      </>
                    :
                    <>
                        <div className="image-wrapper">
                          <img
                            id="imageModal"
                            className="image"
                            src={props.image}
                          />
                        </div>
                    </>
                    }

                  </div>
            </div>
          </div>

          </DialogBody>
          <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={onClose}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
          </DialogFooter>
        </Dialog>
        </div>
    </DialogBlur>
  );
  
};

