import { useState } from 'react';
import styled from "styled-components";
import tw from "twin.macro";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {srv_postRegisterBetaProgram} from "services/osais";

const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm max-w-xs sm:flex sm:max-w-[75%] mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-2 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;
const SubscribeFootnote = tw.p `antialiased font-sans italic text-sm leading-normal text-gray-700 mt-2 flex items-center justify-center gap-2 font-normal opacity-50`;
const SubscribeFormSubmitted = tw.form`mt-6 lg:mt-8 p-4 max-w-[75%] bg-[#00000006] outline outline-dashed outline-1 outline-[#00000024]`;
const SubscribeNoticeOK = tw.div `text-primary-800 flex items-center justify-center`;
const SubscribeNoticeError = tw.div `text-red-600 font-bold`;


export default (props) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [wasSentOK, setWasSentOK] = useState(false);
  const [wasSentError, setWasSentError] = useState(false);
  const [wasSentDup, setWasSentDup] = useState(false);
  
  const validateEmail = (email) => {
    // Simple regex validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email); 
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValid(validateEmail(e.target.value));
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    if(isValid) {
      srv_postRegisterBetaProgram({
        email: email
      })
      .then (data => {        
        if(data && data.data) {
          setWasSentOK(true);
        }
        else {
          if(data.status===409) {
            setWasSentDup(true);
          }
          else {
            setWasSentError(true);
          }
        }
      })
      .catch (err => {
        setWasSentError(true);
      })  
    }
  }
  
  return (
    <div>      
      {wasSentOK ? 
        <>
          <SubscribeFormSubmitted>
            <SubscribeNoticeOK>You are in the list, an email is on its way!</SubscribeNoticeOK>
            <SubscribeFootnote>Check your emails, including spam folder</SubscribeFootnote>
          </SubscribeFormSubmitted>              
        </>
          : 
        wasSentDup ? 
            <SubscribeFormSubmitted>
              <SubscribeNoticeOK>This email is already registered with us</SubscribeNoticeOK>
              <SubscribeFootnote>Please make sure you sign-in with the same credentials</SubscribeFootnote>
            </SubscribeFormSubmitted>              
          :
        wasSentError ? 
          <SubscribeFormSubmitted>
            <SubscribeNoticeError>Wow, seems that we could not register this email!</SubscribeNoticeError>
            <SubscribeFootnote>Sorry for this, email us at info @ opensourceais.com</SubscribeFootnote>
          </SubscribeFormSubmitted>              
        :
      <>
        <SubscribeForm onSubmit={handleOnClick} className={props.center? "ml-auto mr-auto": ""}>
          <Input 
            type="email" 
            value={email}
            onChange={handleEmailChange}
            placeholder="Your Email Address" 
          />
          <SubscribeButton 
            type="submit"
            disabled={!isValid}
          >Get into Wait List!</SubscribeButton>
        </SubscribeForm>
        <SubscribeFootnote>Enter a valid email address</SubscribeFootnote>
      </>
      }
    </div>

  );
};
