import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Section, SectionTitle, HelpTooltip, HelpIconStyle, DisabledStyle} from "./uiStyles";
export const Select=styled.select `${tw` w-full h-full bg-transparent font-sans font-normal text-left outline outline-1 focus:outline-1  transition-all  text-sm px-3 py-3 mt-2 rounded-[7px] border-gray-900 border-t-transparent  ` }`;
export const Option=styled.option `${tw` pt-[9px] py-2 px-3 rounded-md leading-tight cursor-pointer select-none outline outline-0 transition-all ` }`;
export const UiSelect = (props) => {
  
  /* 
  *   UI 
  */

  return(
    <Section>
      <SectionTitle>{props.title}</SectionTitle>

      {props.aOption && props.aOption.length>0 ?

        <Select 
          label="Select voice"
          disabled={props.disabled===true}
          onChange={(elt) => {
            props.onChange(elt.target[elt.target.selectedIndex].value)
          }}
        >
          {props.aOption? props.aOption.map(item => (
            <Option
              key = {item.value}            
              disabled = {item.isDisabled || item.value==null}
              value = {item.value}
            >
              {item.text}
            </Option>
          )): ""}

      </Select>
      :""}
    </Section>
  );
}

