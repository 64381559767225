import React from "react";

const logout=function(){
  /* remove the cookie and reload */
  document.cookie = "jwt_DEBUG_token_OpenSourceAIs=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  document.cookie = "jwt_token_OpenSourceAIs=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  
  // go home
  window.location.href="/";
}

export default (props) => (
  <div >
    {props.user? 
      logout() 
    : 
      ""
    }
  </div>
);
