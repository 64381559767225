
// 
//    We have this utility to keep better maintenance between the various use of the Zoom Dialog UI (js, jsx, react, etc...)
//

const minH=10;
const minW=10;

let props=null;
let cropNatImgHeight = 256;    // crop image natural height
let cropNatImgWidth = 256;     // crop image natural width
let cropEltHeight =256;        // elt image height
let cropEltWidth = 256;        // elt image width
let cropRatio = 1;             // cropping ratio based on real image size
let scaleImageCrop = 1         // scaling for UI
let containerRect = {          // container rect (for recalc of crpiing when zoom in / out)
  x:0,
  y:0, 
  w: 256, 
  h: 256
};      

// the react crop variable, which needs to be sent back each time we change it...
let crop = {
  unit: 'px',
  x: 0,
  y:0,
  aspect: 1,
  width: 256,
  height: 256
}; 


/* 
 *      utilities
 */

const _setInitialCropArea = (objRatio) => {
  // set cop position according to best guess
  let _size=Math.min(objRatio.eltW, objRatio.eltH)*0.8;
  let _crop={
    unit: "px",
    x: (objRatio.eltW-_size)/2,
    y: (objRatio.eltH-_size)/2,
    aspect: props? props.aspect: 1,
    width: _size,
    height: _size
  }
  return util_onImgCropChange(_crop);
}

const _setCrop = (attr) => {
  crop=attr;
}

const util_setProps =(_props) => {
  props=_props;

  // reset some globals
  cropNatImgWidth=_props.defaultW;
  cropNatImgHeight=_props.defaultH;
  containerRect.w=_props.defaultW;
  containerRect.h=_props.defaultH;
}

// recalc the correct crop based on image display ratio
const _resetCroppingRatio =(idElt) => {
    let ratio=1;
    let natW=0;
    let natH=0;
    let eltH=0;
    let eltW=0;
    const eltImg = document.getElementById(idElt);
    if(eltImg) {
      eltW=eltImg.width;
      eltH=eltImg.height;
      natW=eltImg.naturalWidth;
      natH=eltImg.naturalHeight;
      ratio = natW/eltW;
    }
    return {
      ratio: ratio,
      nW: natW,
      nH: natH,
      eltW: eltW,
      eltH: eltH
    }
  }

  const util_onImgCropChange  = (_crop) => {

    // do not let shit in...
    if(!_crop.height || _crop.height<minH) {_crop.height=props.cropHeight;}
    if(!_crop.width || _crop.width<minW) {_crop.width=props.cropWidth;}
    if(!_crop.aspect) {_crop.aspect=1}    // do not let shit come with aspect...

    // if w or h changed, take largest change as base 
    if(_crop.width!= _crop.height*_crop.aspect) {

      // make sure we do not go outside frame...
      if(_crop.width+_crop.x>cropEltWidth.current) {
        _crop.width=cropEltWidth.current-_crop.x;
      }
      if(_crop.height+_crop.y>cropEltHeight.current) {
        _crop.height=cropEltHeight.current-_crop.y;
      }

      // one must change
      if(_crop.width !== crop.width) {
        _crop.height=_crop.width*_crop.aspect;
      }
      else {
        _crop.width=_crop.height*_crop.aspect;
      }

    }
    cropRatio=(_crop.width/cropEltWidth.current);
    _setCrop(_crop);
    return _crop;
  }

  const util_onImgCropResetScale = (idElt) => {
    scaleImageCrop=1;
    _transformCropScale(idElt);

    // change ratios?
    let objRatio=_resetCroppingRatio(idElt);

    cropRatio=objRatio.ratio;
    cropNatImgHeight=objRatio.nH;
    cropNatImgWidth=objRatio.nW;
    cropEltHeight=objRatio.eltH;
    cropEltWidth=objRatio.eltW;    
    let _crop=_setInitialCropArea(objRatio);
    util_adjustImagePos("imageContainer");
    return _crop
  }

   // adjust image vertically centered in the Zoom in case it is too high vs wide
   const util_adjustImagePos=(idCont) => {
    let eltCont=document.getElementById(idCont);
    if(eltCont) {
      let eltWrap=eltCont.childNodes[0];
      if (eltWrap) {
        containerRect= {
          x:eltWrap.clientLeft,
          y:eltWrap.clientTop,
          w:eltWrap.clientWidth,
          h:eltWrap.clientHeight
        }  
      }

      let eltCrop=eltWrap.childNodes[0];
      if(eltCrop) {
        let _ratio=((eltCrop.clientHeight - eltWrap.clientHeight) / 2) / eltCrop.clientHeight;
        if(_ratio) {
          eltCrop.style.transform = "translateY(-"+_ratio*100+"%)";
        }
      }  
    }
  }

  const _transformCropScale  = (idElt) => {
    let eltImage=document.getElementById(idElt);
    if(eltImage) {
      eltImage.style.transform="scale("+scaleImageCrop+")";
    }
  }
 
  const util_onImgCropZoomIn  = (idElt) => {
    scaleImageCrop+=0.15;
    _transformCropScale(idElt);
    util_adjustImagePos("imageContainer");
  }

  const util_onImgCropZoomOut  = (idElt) => {
    scaleImageCrop-=0.15;
    _transformCropScale(idElt);
    util_adjustImagePos("imageContainer");
  }

  
  const util_async_getCroppedImage = async (idElt, fileName) => {
    let img = document.getElementById(idElt);
    if(!img || !crop) {
      return;
    }

    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const ctx = canvas.getContext('2d');
    const ratioUI=cropNatImgHeight/cropEltHeight;

    canvas.width = props.cropWidth;
    canvas.height = props.cropHeight;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';    
    let xImg=(containerRect.w - (img.width * scaleImageCrop))/2;
    let yImg=(containerRect.h - (img.height * scaleImageCrop))/2;
    ctx.drawImage(
      img,
      ((crop.x-xImg)/ scaleImageCrop) * ratioUI,
      ((crop.y-yImg) / scaleImageCrop) * ratioUI,
      (crop.width / scaleImageCrop) * ratioUI* pixelRatio,
      (crop.height / scaleImageCrop) * ratioUI* pixelRatio,
      0,
      0,
      props.cropWidth,
      props.cropHeight
    );

    return new Promise((resolve, reject) => {

      // blob to file so that we can send it
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            resolve(null);
          }
          const file = new File([blob], fileName, blob)
          resolve(file);
        }, 'image/jpeg', 1
      );
    });
  }

  const util_async_resizeImage = async(imgElt, width, height, fileName) => {
      var mainCanvas = document.createElement("canvas");
      mainCanvas.width = width;
      mainCanvas.height = height;
      var ctx = mainCanvas.getContext("2d");
      ctx.drawImage(imgElt, 0, 0, width, height);
      return new Promise((resolve, reject) => {

        // blob to file so that we can send it
        mainCanvas.toBlob(
          (blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error('Canvas is empty');
              resolve(null);
            }
            const file = new File([blob], fileName, blob)
            resolve(file);
          }, 'image/jpeg', 1
        );
      });
  }
  

  export { 
    util_setProps,
    util_onImgCropZoomIn,
    util_onImgCropZoomOut,
    util_onImgCropChange,
    util_onImgCropResetScale,
    util_adjustImagePos,
    util_async_getCroppedImage,
    util_async_resizeImage
  }
