import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { MyAlert } from './myAlert'; 


/* css */

const Container = tw.div`relative sm:mx-20 my-8 m-0 mb-6 `;
const Content = tw.div`grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-3 `;

const SnapshotContainer = tw.div`relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md mx-4 w-[300px] `;
const SnapshotStamp = tw.div`bg-clip-border mx-8 rounded-xl overflow-hidden text-gray-700 shadow-lg absolute -mt-6 grid h-16 w-16 place-items-center p-1 border-2 bg-white z-10`;  //bg-lime-100 border-lime-200
const SnapshotStamp_text = tw.p`block antialiased font-sans text-xs font-normal `;  // text-blue-gray-600
const SnapshotHeader = tw.h5`block antialiased tracking-normal font-sans text-xl font-semibold leading-snug  capitalize`; // blue-gray text-inherit 
const SnapshotSubHeader = tw.p`block antialiased font-sans text-base font-light leading-relaxed `; // text-inherit 
const SnapshotRepoButton = tw.a` font-sans font-bold uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg  bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-md  hover:shadow-lg active:opacity-[0.85]`;
const SnapshotFooter = tw.div`border-t p-4 `; // border-blue-gray-50
const SnapshotFooterButton = tw.a` font-sans font-bold uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-4 px-6 rounded-lg bg-blue-500 shadow-md hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block w-full text-center text-white hover:scale-[1.02] focus:scale-[1.02] active:scale-100 `; // shadow-blue-500/20 hover:shadow-blue-500/40
const DivRight = tw.div`p-4 text-right`;

// authenticate into OSAIS
let _hasCalledGetALLAis=false;

export function SnapshotSoloImage(props) {
  const [valSlider, setValSlider] = useState(50);

  const onReveal = (evt) => {
    let _pcReveal=parseInt(evt.target.value);
    setValSlider(_pcReveal);
    let eltImg=evt.target.previousElementSibling;
    eltImg.style.clip = "rect(0px, "+(eltImg.clientWidth*_pcReveal/100)+"px, auto, 0px)";  
    evt.target.style.background= "linear-gradient(to right, #f0f6c7 0%, #f0f6c7 "+_pcReveal+"%, #fff "+_pcReveal+"%, #fff 100%)";
  }

  return (
    <div className="imgContainer ">
      <div className="reveal marketing">
        <img src="/assets/preview_ping.jpeg" className="centeredImage imgBack" tooltip="Original" title="Original" />
        <img src={"/assets/preview_"+props.engine+".jpeg"} className="centeredImage imgTop" tooltip="women from forest deep look photo realistic" title="women from forest deep look photo realistic"/>
        <input type="range" min="0" max="100" className="slider" value={valSlider} onChange={onReveal}/>
      </div>
    </div>
  )
}

export function SnapshotAI(props) {
  return (
      <SnapshotContainer >
        <SnapshotStamp className="left-25pc">
          <SnapshotStamp_text>
          {props.engine}          
          </SnapshotStamp_text>
        </SnapshotStamp>

        <DivRight >
          <SnapshotSoloImage 
            engine = {props.engine}
          />
          <SnapshotHeader>{props.name}</SnapshotHeader>
          <SnapshotSubHeader >{props.description}</SnapshotSubHeader>
          <div className="mt-4">
            <SnapshotRepoButton  
              type="button"
              href={props.github}
            >
              check repo...
            </SnapshotRepoButton>
          </div>
        </DivRight>
        <SnapshotFooter>
          <SnapshotFooterButton 
            type="button"
            href={"/WorldOfAIs/ai/"+props.engine}
          >
            Use this AI!
          </SnapshotFooterButton>
        </SnapshotFooter>
      </SnapshotContainer>
    )
}

export function SnapshotAllAIs(props) {

  const [alertMsg, setAlertMsg] = useState(null);

/* 
*   UI 
*/

  return (
    <Container>

      {alertMsg? 
        <MyAlert 
          text={alertMsg}
        />
        :""
      }

      <Content>
      {props.aAIAgent.map((item, index) => (
          <SnapshotAI 
            key = {index}
            {...item}
          />
      ))}
      
    </Content>
  </Container>

  );

}
