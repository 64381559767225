import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import {setSEO_title, setSEO_description, setSEO_type, setSEO_image } from "services/seo";

import createClient  from "@sanity/client";
import {PortableText} from '@portabletext/react'
import imageUrlBuilder from "@sanity/image-url";
import {getImageDimensions} from '@sanity/asset-utils'

import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton } from "components/misc/Buttons";
import { SectionHeading, Subheading } from "components/misc/Headings.js";

const ButtonContainer = tw.a `flex justify-center mb-16 -mt-8`;
const BackButton = tw(PrimaryButton)`mt-16 mx-auto`;

const Details = tw.div`mt-16 p-2 rounded border-2 rounded-t-none border-dashed border-primary-100 bg-[#eceff1] flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center w-full relative`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-lg inline-flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;
const MetaDate = styled.div` ${tw`absolute right-[0px] text-secondary-100 font-medium text-lg inline-flex items-center leading-none mr-6 last:mr-0`}`;
const ProfilePict = styled.img `${tw`w-8 h-8 rounded-[48px] inline-flex ml-4 mr-2`}`;
const ProfileName = styled.span `${tw` font-bold`}`;

const Article = tw.div `p-2 w-full`;
const H1 = tw.h1 `mt-4 mb-8 text-xl font-bold text-left`;

const builder = imageUrlBuilder(createClient ({
  projectId: "sorm36lq", 
  dataset: "production",
  useCdn: true,

}));
function urlFor(source) {
  return builder.image(source);
}

let _lastPath=null;

export default (props) => {
  const [author, setAuthor] = useState(null); 
  const [article, setArticle] = useState(null); 

  const {path} = useParams();

  useEffect(() => {
    if(path!=_lastPath && props.aArticle) {
      // amend type to article
      setSEO_type("article");
     
      let _article=getArticleFromLocation(path);
      if(_article) {
        _lastPath=path;
        let _author=getAuthor(_article.author._ref);
        setAuthor(_author);
        setArticle(_article);
      }
    }
  });

  
  const getArticleFromLocation = function (_location)  {
    for(var i=0; i<props.aArticle.length; i++) {
      if (props.aArticle[i].slug.current === _location) {

        // seo here      
        if(props.aArticle[i].headlineImage) {
          setSEO_title(props.aArticle[i].title);
          setSEO_description(props.aArticle[i].seo? props.aArticle[i].seo : "OSAIS article");      
          setSEO_image(props.aArticle[i].headlineImage);
        }

        return props.aArticle[i];
      }
    }
    return null;
  }

  const getCategory = (aCat) => {
    if(props.aCategories && aCat && aCat.length>0) {
      let i=props.aCategories.findIndex(function (x) {return (x._id===aCat[0]._ref)});
      if(i!==-1) {
        return props.aCategories[i].title;
      }  
    }
    return "Blog post"    
  }  

  const getAuthor = (_ref) => {
    if(props.aAuthor && _ref ) {
      let i=props.aAuthor.findIndex(function (x) {return (x.id===_ref)});
      if(i!==-1) {
        return props.aAuthor[i];
      }  
    }
    return null;  
  }  

  const getFormattedDate = (_date) => {
    let _tmp=new Date(_date);
    return _tmp.toLocaleString('default', { month: 'long' }) + " "+_tmp.getUTCDate()+", "+_tmp.getUTCFullYear();
  }   
  
  const renderAuthor = function () {
    if(!author) {
      return (<></>)
    }

    return (
      <div>
        <span>Author: </span>
        <ProfilePict src={(author.picture? author.picture : "")}/>        
        <ProfileName>{author.name}</ProfileName>
      </div>
    )
  }

  const SampleImageComponent = ({value, isInline}) => {
    const {width, height} = getImageDimensions(value);
    return (
      <img
        src={imageUrlBuilder({
          projectId: props.blogger,
          dataset: "production"
        })
          .image(value)
          .width(isInline ? 100 : 800)
          .fit('max')
          .auto('format')
          .url()}
        alt={value.alt || ' '}
        loading="lazy"
        style={{
          // Display alongside text if image appears inside a block text span
          display: isInline ? 'inline-block' : 'block',
  
          // Avoid jumping around with aspect-ratio CSS property
          aspectRatio: width / height,
        }}
      />
    )
  }

  const myPortableTextComponents = {
    types: {
      image: SampleImageComponent,
      youtube: ({value}) => <iframe src={value.url} width="320" height="320"/>,
      callToAction: ({value, isInline}) =>
        isInline ? (
          <a href={value.url}>{value.text}</a>
        ) : (
          <div className="callToAction">{value.text}</div>
        ),
    }
  }

  return (
  <AnimationRevealPage>
    <Header 
        user = {props.user}
    />    

    {article?
      <Details>
        <MetaContainer>
          <Meta>
            {renderAuthor()}
          </Meta>
          <Meta>
          <div>{getCategory(article.categories? article.categories : [])}</div>
          </Meta>
          <MetaDate>
            {getFormattedDate(article._updatedAt)}
          </MetaDate> 
        </MetaContainer>
      </Details>
    :""}

    {article?
      <Article className="article">
          <a href={"/article/"+article.slug.current}>
            <H1>{article.title? article.title: ""}</H1>
          </a>
          
          <PortableText
            value={article.body}
            components = {myPortableTextComponents}
          />
      </Article>
    :""}

    <ButtonContainer href="/blog">
        <BackButton >Back to the Blog</BackButton>
    </ButtonContainer>

    <Footer />
  </AnimationRevealPage>
)};
