import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {Section, SectionTitle, HelpTooltip, HelpIconStyle} from "./uiStyles";

/* css */
const RangeValue=styled.div `${tw`relative inline-flex text-xl font-semibold` }`;
const Slider=styled.input `${tw`w-48 h-4 rounded-lg appearance-none my-2 bg-blue-100`}`;

export const UiSlider = (props) => {
  
  /* 
  *   UI 
  */

  let _unit="%";
  if(props.hasOwnProperty("unit") && props.unit!=="percent" && props.unit!=="%") {
    _unit=props.unit;
  }

  return(   
      <Section>
        <SectionTitle>
          <span>{props.title}</span>
          <AiOutlineQuestionCircle data-tooltip-target="tooltip-guidance" style={HelpIconStyle} />
          <HelpTooltip id="tooltip-guidance" role="tooltip" >
              {props.tooltip}
              <div className="tooltip-arrow" data-popper-arrow></div>
          </HelpTooltip>
        </SectionTitle>

        <Slider
          type="range"
          className={props.minValue==props.maxValue? "hidden": ""}
          min={props.minValue}
          max={props.maxValue}
          value={props.curValue}
          onChange={props.onChange}
        />
        <RangeValue >{props.curValue + _unit}</RangeValue>
      </Section>
  );
}

