import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import 'tw-elements';
import WaveImage from "../../images/soundwave.jpg";

// CSS
const Relative=styled.div `${tw` relative max-w-lg w-full py-4 ` }`;

const Toolbar=styled.button `${tw` absolute right-0 bottom-0  my-4 mx-2 ` }`;
const Button=styled.button `${tw`  bg-orange-600 opacity-75 rounded-sm mx-2 py-1 px-4 text-white ` }`;

export const MyMusicPlayer = (props) => {

  return (
    <Relative
      id="musicPlayer"
    >

    {props.aFile && props.aFile.length>0 ? 
    <>
        {props.aFile.map((_file, i) => (
          <div
            key = {i}
          >
          {_file && _file.url ?
            <audio controls
              className="mx-auto"
            >
              <source src={_file.url} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          :""}
          </div>
        ))}

    </>
    :""}

    </Relative>     
  );
};
