import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import 'tw-elements';

// CSS
const Relative=styled.div `${tw` relative max-w-lg w-full py-4 ` }`;

export const MyVideoPlayer = (props) => {

  return (
    <Relative
      id="videoPlayer"
    >

    {props.aFile && props.aFile.length>0 ? 
    <>
        {props.aFile.map((_file, i) => (
          <div
            key = {i}
          >
          {_file && _file.url ?
            <video controls 
              className="mx-auto w-full"
            >
              <source src={_file.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          :""}
          </div>
        ))}

    </>
    :""}

    </Relative>     
  );
};
