import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

import {setSEO_title, setSEO_description } from "services/seo";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

let wasSetSEO=false;
export default (props) => {

  useEffect(() => {
    if(!wasSetSEO) {
      wasSetSEO=true;
      document.title = 'OSAIS - Contact Us';
      setSEO_title("Contact us at OpenSourceAIs - OSAIS");
      setSEO_description("Access details to contact us at OpenSourceAIs");
    }
  }, []);

  return (
    <AnimationRevealPage>
      <Header 
        user = {props.user}
      />
      <ContactUsForm />

      <Footer 
        user = {props.user}
        aAIAgent = {props.aAIAgent}
      />
    </AnimationRevealPage>
  );
};
