import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Section, SectionTitle, HelpTooltip, HelpIconStyle, DisabledStyle} from "./uiStyles";
export const TogSwitch=styled.label `${tw` mt-2 mb-2  ` }`;

export const UiSwitch = (props) => {
  
  /* 
  *   UI 
  */

  return(
    <Section>
      <SectionTitle>{props.title}</SectionTitle>
      <TogSwitch className="switch">
        <input 
          type="checkbox" 
          checked={props.selected===true} 
          disabled={props.disabled===true}
          onChange={(elt) => {
            props.onChange(elt.target.checked)
          }}
        />
        <span className="slider round"
          checked={props.selected} 
        />
      </TogSwitch>
    </Section>
  );
}

