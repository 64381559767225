import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import 'tw-elements';
import {
  Carousel,
  initTE,
} from "tw-elements";

initTE({ Carousel });

// CSS
const Relative=styled.div `${tw` relative max-w-lg w-full ` }`;
const CarouselIndicator=styled.button `${tw`mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none` }`;
const CarousetIndicContainer=styled.div `${tw`absolute inset-x-0 bottom-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0` }`;
const CarouselBtnLeft=styled.button `${tw`absolute top-0 bottom-0 left-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none` }`;
const CarouselBtnRight=styled.button `${tw`absolute top-0 bottom-0 right-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none` }`;
const CarouselBtnText=styled.span `${tw`!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]` }`;
const CarouselBtnSvg=styled.svg `${tw` h-6 w-6` }`;
const CarouselBtnSvgContainer=styled.span `${tw` inline-block h-8 w-8` }`;
const CarouselImage=styled.img `${tw` block w-full ` }`;
const CarouselImageContainer=styled.div `${tw` relative float-left -mr-[100%] w-full !transform-none opacity-90 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none` }`;
const CarouselImagesContainer=styled.div `${tw` relative w-full overflow-hidden after:clear-both after:block after:content-['']` }`;

export const MyCarousel = (props) => {

  const renderIndicator = (i, isSelected) => {
    let obj=CarouselIndicator;
    if(isSelected) {
      return (
        <CarouselIndicator
          type="button"
          key={i} 
          data-te-target="#carouselExampleCrossfade"
          data-te-slide-to={i}
          data-te-carousel-active
          aria-current="true"
          aria-label={"Slide "+i} />
      );  
    }    

    return (
      <CarouselIndicator
        type="button"
        key={i} 
        data-te-target="#carouselExampleCrossfade"
        data-te-slide-to={i}
        aria-label={"Slide "+i} />
    );
  }
  const renderImage = (url, i, isSelected) => {
    // rebase URL (it s ours anyway) => we remove the hostname from the url
    let _url=url;
    try {
      const urlObject = new URL(url);
      _url=urlObject.pathname;
    }
    catch(err){}
    if(isSelected) {
      return (
        <CarouselImageContainer
          data-te-carousel-fade
          data-te-carousel-item
          data-te-carousel-active
          key={i} 
        >
            <CarouselImage
            src={_url} 
            alt={"image" + i}
            className="active"
          />
        </CarouselImageContainer>
      );  
    }

    return (
      <CarouselImageContainer
        data-te-carousel-fade
        data-te-carousel-item
        key={i} 
      >
          <CarouselImage
          src={url} 
          alt={"image" + i}
        />
      </CarouselImageContainer>
    );
  }

  return (
    <Relative
      id="carouselExampleCrossfade"
      data-te-carousel-init
      data-te-carousel-slide
      className = {props.aImage.length>1? " multi": " mono"}
      >

            <CarousetIndicContainer
              data-te-carousel-indicators>
              {props.aImage.map((_image, i) => (
                renderIndicator(i, i===0)
              ))}
            </CarousetIndicContainer>

            <CarouselImagesContainer>
              {props.aImage.map((_image, i) => (
                renderImage(_image.url, i, i===props.aImage.length-1)
              ))}
            </CarouselImagesContainer>

            <CarouselBtnLeft
              type="button"
              className="SideNavCarousel"
              data-te-target="#carouselExampleCrossfade"
              data-te-slide="prev">
              <CarouselBtnSvgContainer>
                <CarouselBtnSvg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5" />
                </CarouselBtnSvg>
              </CarouselBtnSvgContainer>
              <CarouselBtnText>Previous
              </CarouselBtnText>
            </CarouselBtnLeft>

            <CarouselBtnRight
              type="button"
              className="SideNavCarousel"
              data-te-target="#carouselExampleCrossfade"
              data-te-slide="next">
              <CarouselBtnSvgContainer >
                <CarouselBtnSvg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </CarouselBtnSvg>
              </CarouselBtnSvgContainer>
              <CarouselBtnText>Next
              </CarouselBtnText>
            </CarouselBtnRight>

          </Relative>     
  );
};
