import React, { useState, useEffect } from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "../images/welcome.gif";
import logo from "../images/logo-full.png";
import facebookIconImageSrc from "../images/facebook-icon.png";
import googleIconImageSrc from "../images/google-icon.png";
import linkedinIconImageSrc from "../images/linkedin-icon.png";
import SIWWIconImageSrc from "../images/logo_siww.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

import {setSEO_title, setSEO_description } from "services/seo";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-8 flex flex-col items-center`;
const Heading = tw.h1`text-xl xl:text-2xl font-extrabold`;
const SubHeading = tw.h2` xl:text-xl `;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const VSeparator= tw.div `w-[24px] `
const Flex= tw.div `flex `

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs min-w-[240px] font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-0 rounded-full`}
  }
  .icon {
    ${tw`w-8 h-8`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`mt-8 mb-4 text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-24 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const  logoLinkUrl = "#";
const  illustrationImageSrc = illustration;
const  socialButtons = [{
    iconImageSrc: facebookIconImageSrc,
    text: "Sign In with Facebook",
    url: "/auth/facebook",
    enabled: true
  }, {
    iconImageSrc: googleIconImageSrc,
    text: "Sign In with Google",
    url: "/auth/google",
    enabled: true
  }, {
    iconImageSrc: linkedinIconImageSrc,
    text: "Sign In with LinkedIn",
    url: "/auth/linkedin",
    enabled: true
  }/*, {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign In with Twitter",
      url: "/auth/twitter"
    }*/];

let wasSetSEO=false;
export default (props) => {
  
  useEffect(() => {
    if(!wasSetSEO) {
      wasSetSEO=true;
      document.title = 'OSAIS - Login';
      setSEO_title("Login into OpenSourceAIs");
      setSEO_description("Authentication into OpenSourceAIs");
    }
  }, []);

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{props.headline}</Heading>
            <SubHeading>{props.subHeadline}</SubHeading>
            <FormContainer>
              <SocialButtonsContainer>
                <SocialButton>
                {socialButtons.map((socialButton, index) => (
                  <Flex key={index}>
                    {socialButton.enabled?
                    <>
                    <VSeparator />
                    <a className="iconContainer" href={socialButton.url}>
                      <img className="icon"
                        src={socialButton.iconImageSrc}  
                        alt={socialButton.text} 
                        title={socialButton.text} 
                      />
                    </a>
                    <VSeparator />
                    </>
                    :""}
                  </Flex>
                ))}
                </SocialButton>

              </SocialButtonsContainer>
              <DividerTextContainer>
                <DividerText>or sign-in Anonymously</DividerText>
              </DividerTextContainer>

            </FormContainer>
              <SocialButton href={"/auth/siww"}>
                    <span className="iconContainer">
                      <img src={SIWWIconImageSrc} className="icon" alt=""/>
                    </span>
                    <span className="text">Sign In with Wallet</span>
              </SocialButton>
              
              <SocialButton href={"/"}>
                    <span className="iconContainer">
                      <img src={logo} className="icon" alt=""/>
                    </span>
                    <span className="text">Back to homepage</span>
              </SocialButton>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
)};
