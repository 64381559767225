import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "feather-icons/dist/icons/trash-2.svg";
import { ReactComponent as MicIcon } from "feather-icons/dist/icons/mic.svg";
import { ReactComponent as CropIcon } from "feather-icons/dist/icons/crop.svg";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import {Section, SectionTitle} from "./uiStyles";
import WaveImage from "../../../images/soundwave.jpg";
import { WaveFile } from 'wavefile';
import { DialogZoom } from './dialog-zoom';
import { UiFileSelectProfile } from './uiSelectProfilePict';
import {util_async_resizeImage} from "./dialog-zoom-utils";
import {TYPE_ALERT_CRITICAL, TYPE_ALERT_WARNING, TYPE_ALERT_INFO} from "../myAlert";
import {FILETYPE_UNKNOWN, FILETYPE_IMAGE, FILETYPE_MOVIE, FILETYPE_VOICE} from './uiConst'; 

// CSS
const DropZone=styled.div `${tw`relative mt-1 flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6` }`;
const FileFormat=styled.div `${tw`text-xs text-gray-500 mt-1 cursor-default `}`;
const FileFormatlabel=styled.div `${tw`w-full mt-1 text-center `}`;
const DropInside=styled.div `${tw`space-y-1 text-center`}`;
const DropIcon=styled.svg `${tw`mx-auto h-12 w-12 text-gray-400`}`;
const FileInput=styled.input `${tw`sr-only`}`;
const FileHidden=styled.div `${`{display:none}`}`;
const BtnMobile=styled.button `${tw`absolute justify-center rounded bg-blue-600 text-white px-4 py-2 min-w-[40px] cursor-pointer` }`;

const DelIconStyle = { backgroundColor: "#ce4b31" }
const BoxStyle = {width: "100%" }

let tsComplete=null;            // have we loaded the file?
let gSelFileWhenDrag=null;      // deal with this shit reload of component when dragging over
let gIsRecordingVoice=false;
let gRecordingTime=0;
let gMediaRecorder=null;
let gAudioChunks = [];

const cMaxRecordingTime=15      // 15 sec max audio recording
const cDefaultPictureSize=512;

export const UiFileSelect = ({
  label,
  updateFilesCb,
  ...otherProps}) => {

    const cFileAttrVoid={
      filename: null,
      srcOriginal: null,
      srcFinal: null,
      kb: 0,
      type: FILETYPE_UNKNOWN
    }
    const cImageAttrVoid={
      filename: null,
      srcOriginal: null,
      srcFinal: null,
      w: 0,
      h:0,
      kb: 0,
      type: FILETYPE_IMAGE
    }

    const uploadedFile = React.useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedFile, setSelectedFile] = useState(gSelFileWhenDrag);
    const [isFileSizeValid, setIsFileSizeValid] = useState(false);        
    const [isImageSizeValid, setIsImageSizeValid] = useState(false);      
    const [isUploading, setIsUploading] = useState(false);                 
    const [fileAttr, setFileAttr] = useState(cFileAttrVoid);   

    const [isZoomDialogOpen, setIsZoomDialogOpen] = useState(false);   
    const [isSelectProfileOpen, setIsSelectProfileOpen] = useState(false);   
    const [useProfilePicture, setUseProfilePicture] = useState(null);   

/* 
*      File upload to browser
*/

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    _preAcceptFile(file);
  };

  const onOpenSelect = (evt) => {
    try {
      let _id=evt.target.dataset.link? evt.target.dataset.link : null;
      if(_id) {
        let elt=document.getElementById(evt.target.dataset.link);
        elt.click();  
      }  
    }
    catch(err){}
  }

  // manual selection of file
  const onSelectFile = (event) => {
    event.preventDefault();
    let selFile=null;
    if(event.target.files.length!==0) {
      selFile=event.target.files[0];
    }
    _preAcceptFile(selFile);
  };
  
  const _preAcceptFile = (file) => {
    if(otherProps.fileType===FILETYPE_IMAGE) {
      if (file.type.startsWith("image/")) {
        _acceptFile(file, true);
      } else {        
        otherProps.onAlert({
          message: "Unrecognized image format. Please use PNG or JPG.",
          type: TYPE_ALERT_CRITICAL
        })
      }  
    }
    else {
      _acceptFile(file, true);
    }
  }

  const getMaxFileSize = () => {
    return (Math.floor(otherProps.maxFileSize/1024)) + "Mb";
  }

  const getAcceptedFormat = () => {
    if(otherProps.aExt) {
      let _strRet="";
      for (var i=0; i<otherProps.aExt.length; i++) {
        if(i>0) {
          _strRet=_strRet+", ";
        }
        _strRet=_strRet+otherProps.aExt[i].toUpperCase();
      }
      return _strRet
    }
    return "any file"
  }

  const getFileSize = () => {
    if(!fileAttr || !fileAttr.kb) {
      return 0;
    }
    return (Math.floor(fileAttr.kb)) + "Kb";
  }

  const getFileType = (_file)  => {
    if(_file.type=== "image/png" || _file.type=== "image/jpeg") {
      return FILETYPE_IMAGE;
    }

    if(_file.type=== "audio/wav" || _file.type=== "audio/ogg") {
      return FILETYPE_VOICE;
    }

    if(_file.type=== "video/mp4" ) {
      return FILETYPE_MOVIE;
    }

    return FILETYPE_UNKNOWN;
  }

  const resetSelection = () => {
    gSelFileWhenDrag=null;
    setSelectedFile(null);
    setFileAttr(otherProps.fileType===FILETYPE_IMAGE? cImageAttrVoid: cFileAttrVoid);
    setIsFileSizeValid(false);
    setIsImageSizeValid(false);
    setUseProfilePicture(null);
    otherProps.onFileReady({
      selectedFile: null,
      srcFile: null,
      fnReset: null
    })
      .then(function(){})  
      .catch(function(){})
  }

  // accept a new file for upload (valid or not?)
  const _acceptFile = (selFile, isOriginal) => {

    // terminate any previous looping on file attr
    if(tsComplete) {
      clearTimeout(tsComplete);
      tsComplete=null;
    }

    /* display image in UI */
    if(selFile) {

      // stop if size too big and not an image
      if(selFile.size/1024 > otherProps.maxFileSize) {
        otherProps.onAlert({
          message: " Upload size limit of 1Mb. Your file was not uploaded",
          type: TYPE_ALERT_CRITICAL
        });
        setSelectedFile(null);
        return;
      }

      // good to go for upload...
      gSelFileWhenDrag=selFile;

      setIsUploading(true);
      const reader = new FileReader();
      const {current} = uploadedFile;
      current.file = selFile;
      reader.readAsDataURL(selFile);

      // async here...
      reader.onload = (e) => {
          current.src = e.target.result;
          if(current.src) {
           
            // required an image ? do not take anything else than PNG/JPEG
            if(otherProps.fileType===FILETYPE_IMAGE && selFile.type!== "image/png" && selFile.type!== "image/jpeg") {
              selFile=null
              setIsFileSizeValid(false);
              setIsImageSizeValid(false); 
            }

            setSelectedFile(selFile);
            if(selFile) {
              _checkComplete(selFile, current.src, isOriginal);
            }
          }
      }
    }
    else {
      otherProps.validateFile(null);
      resetSelection();
    }
  }

  // need to wait for image fully loaded to know its size... this is the trick
  const _checkComplete = (_selFile, src, isOriginal) => {

    // if an image.. wait for it to load in the UI to gett extra attributes, otherwise, we can go
    if ((uploadedFile.current && uploadedFile.current.complete) || (getFileType(_selFile)!==FILETYPE_IMAGE)) {
        // update fileAttr
        let objAttr=fileAttr;
        objAttr.srcFinal= src;
        objAttr.filename= _selFile.name.substring(_selFile.name.lastIndexOf('/')+1);
        if(_selFile) {
          objAttr.kb= _selFile.size/1000;
          objAttr.type= getFileType(_selFile);  
        }
        if(objAttr.type===FILETYPE_IMAGE) {
          objAttr.w= uploadedFile && uploadedFile.current? uploadedFile.current.naturalWidth: 0;
          objAttr.h= uploadedFile && uploadedFile.current? uploadedFile.current.naturalHeight: 0;  

        }
        if(isOriginal) {
          objAttr.srcOriginal=src;    // only change original if original changed...
        }
        setFileAttr(objAttr);  

        // check validity of file
        let objIsValid=otherProps.validateFile(objAttr); 
        setIsFileSizeValid (!objIsValid.isTooBig);

        if(objIsValid.isTooBig) {
          if (objAttr.type===FILETYPE_IMAGE) {
            let _filenameNoExt=gSelFileWhenDrag.name.replace(".jpg","").replace(".jpeg","").replace(".png","");
            let _newFilename= _filenameNoExt+'-resized.jpg';
            let _smallSide=Math.min(objAttr.w, objAttr.h);   // the smallest of w, h
            let _ratio=cDefaultPictureSize/(Math.max(_smallSide, cDefaultPictureSize));
            let _h=Math.floor(objAttr.h * _ratio);
            let _w=Math.floor(objAttr.w * _ratio);
            let _img=document.getElementById("idLoadedSourceImage");
            util_async_resizeImage(_img, _w, _h, _newFilename)
              .then((_file) => {
  
                otherProps.onAlert({
                  message: " Upload size limit of 1Mb. Your image was resized to fit",
                  type: TYPE_ALERT_INFO
                });
      
                _acceptFile(_file, false)
              })
              .catch((err) => {})  
          }
          return;
        }

        if(objIsValid.isFitRatio===false) {
          otherProps.onAlert({
            message: "Change image width/height ratio to continue...",
            type: TYPE_ALERT_INFO
          });

          setIsZoomDialogOpen(true);
          return;
        }

        // advise to use cropping 
        if(objAttr.w!=objAttr.h) {
          otherProps.onAlert({
            message: "You may want to use the cropping tool to get a square image...",
            type: TYPE_ALERT_INFO,
            delay: 4500
          });
        }

        setIsUploading(false);
        tsComplete=null;

        // call back
        if(otherProps.onFileReady) {
          otherProps.onFileReady({
            selectedFile: gSelFileWhenDrag,
            srcFile: objAttr.srcFinal,
            fnReset: resetSelection
          })
            .then(function(data){
              // file was uploaded
              /*
              otherProps.onAlert({
                message: "Image is ready to use",
                type: TYPE_ALERT_INFO
              })
              */
            })  
            .catch(function(err){
              // error upload
              otherProps.onAlert({
                message: "Could not upload. Was the file too large?",
                type: TYPE_ALERT_CRITICAL
              })
            })    
        }
      }
      else {
          tsComplete=setTimeout(() => {
            _checkComplete(_selFile, src, isOriginal);
          }, 250)
      }    
  }

  const onImageClick = () => {
    // not much to do here
  }

  const onImageDelete = () => {
    resetSelection();
  }

/*
 *      Voice file mgt
 */

  const buttonBlinkEffect = (_partialId, _startDate) => {
    let _eltBtn=document.getElementById("idRecordBtn_"+_partialId);
    let _eltCD=document.getElementById("idCountdown_"+_partialId);
    if(_eltBtn && _eltCD) {
      let bgCol=_eltBtn.style.background;
      let _timer=500;
      if(bgCol==="rgb(200, 50, 50)") {
        _eltBtn.style.background=" rgb(255 50 50)";
      }
      else {
        _eltBtn.style.background="rgb(200, 50, 50)";
      }

      let _now=new Date();
      let diffSec=Math.floor((_now - _startDate) / 1000);
      _eltCD.innerHTML=""+(cMaxRecordingTime-diffSec);

      if(cMaxRecordingTime-diffSec>0 && gIsRecordingVoice) {
        setTimeout(() => {
          buttonBlinkEffect(_partialId, _startDate);
        }, _timer);  
      }
      else {
        _eltBtn.style.background="rgb(49, 130, 206)";
        gIsRecordingVoice=false;
      }
    }
  }

  const startVoiceRecording = (_partialId) => {
    let _eltCD=document.getElementById("idCountdown_"+_partialId);
    if(_eltCD) {_eltCD.style.display = 'block';}

    if(gMediaRecorder) {
      gMediaRecorder.start();
    }
  }

  const stopVoiceRecording = (_partialId) => {
    let _eltCD=document.getElementById("idCountdown_"+_partialId);
    if(_eltCD) {_eltCD.style.display = 'none';}

    if(gMediaRecorder) {
      gMediaRecorder.stop();
      gIsRecordingVoice=false;
    }
  }

  const onStartRecordingVoice = (_partialId) => {
      if(!gIsRecordingVoice) {
        gIsRecordingVoice=true;
        gRecordingTime=0;
        let now=new Date()
  
        buttonBlinkEffect(_partialId, now);  

        // record
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
      
          gMediaRecorder = new MediaRecorder(stream);
          gAudioChunks=[];
          gMediaRecorder.addEventListener("dataavailable", event => {
            gAudioChunks.push(event.data);
          });

          // this is where we get the recordings...
          gMediaRecorder.addEventListener('stop', () => {

              // create a fake (simulated) file for this recording
              let _ts=new Date().getTime();
              const _filename=otherProps.user && otherProps.user.username!== "null" ? otherProps.user.username+'.wav' : "demo_"+_ts+".wav"
              const audioBlob = new Blob(gAudioChunks);
              const simulatedFile = new File([audioBlob], _filename, {type: 'audio/wav'});
              gSelFileWhenDrag=simulatedFile;
              setSelectedFile(simulatedFile);

              _acceptFile(simulatedFile, true);
              gMediaRecorder=null;

              // get the base64 and complete upload
              /*
              const reader = new FileReader();
              reader.readAsDataURL(audioBlob, 'audio/wav');              
              reader.onloadend = () => {
                const base64data = reader.result;
                _checkComplete(base64data, true);  
              }
              */
          });
      
          startVoiceRecording(_partialId);
          setTimeout(() => {
            if(gIsRecordingVoice) {
              stopVoiceRecording(_partialId);
            }
          }, cMaxRecordingTime*1000);

        })
        .catch(err => {
          console.log("Could not record file from mobile");
        });
      }
      else {
        stopVoiceRecording(_partialId);
      }
  }

/*
 *    Profile List
 */

  const onChooseFromProfile = () => {
    if(otherProps.user && (!otherProps.user.aPicture || otherProps.user.aPicture.length==0)) {
      otherProps.onAlert({
        message: "Add profile pictures to your profile to use this feature",
        type: TYPE_ALERT_WARNING
      })
    }
    setIsSelectProfileOpen(!isSelectProfileOpen);
  }

  const onSelectProfile = (iProfile, _s3, _url) => {

    // show image 
    setUseProfilePicture(_url);
    if(otherProps.onS3Selected) {
      otherProps.onS3Selected(_s3);
    }
    setIsSelectProfileOpen(false);
  }

/*
 *    Zoom dialog 
 */

  const onCloseZoom = () => {
    setIsZoomDialogOpen(false);
  }

  const async_onCrop = async (fileImage) => {
    if(fileImage) {
      _acceptFile(fileImage, false);
    }
    setIsZoomDialogOpen(false);
  }

  const onPrepareZoom = () => {
    setIsZoomDialogOpen(true);
  }

/*
 *    Renders
 */

  const _renderDropFile = (_type, _src) => {
    return (
    <div>

        {_type===FILETYPE_IMAGE && (selectedFile || useProfilePicture)? 
        <>
          <img 
            id="idLoadedSourceImage"
            src={selectedFile? URL.createObjectURL(selectedFile): useProfilePicture} 
            className={_type===FILETYPE_IMAGE? "border ": "hidden"}
            alt={selectedFile? fileAttr.filename: "Profile picture"}
            ref={uploadedFile}
          />

            <BtnMobile 
              id = {"idCropBtn_"+otherProps.id}
              className="right-[2px] top-[2px]"
              onClick={onPrepareZoom}
              title="Crop image"
            >
              <CropIcon  id = {"idCrop_"+otherProps.id} />
            </BtnMobile>
          </>
        :""}

        {_type===FILETYPE_VOICE && selectedFile? 
          <audio controls 
              className="w-36 my-2 inline-flex"
              src = {URL.createObjectURL(selectedFile)} 
            />
        :""}

        <BtnMobile 
          id = {"idDeleteBtn_"+otherProps.id}
          className="right-[2px] bottom-[2px]"
          style= {DelIconStyle}
          onClick={onImageDelete}          
          title="Reset choice"
        >
          <DeleteIcon  id = {"idDelete_"+otherProps.id} />
        </BtnMobile>

        {_type===FILETYPE_IMAGE? 
          <img 
            src={_src} 
          />
        :""}


      {selectedFile?
        <FileFormat>
          <i className={fileAttr.isFileSizeValid ? "green": "red"}></i>
          {_type===FILETYPE_IMAGE ? 
            <FileFormatlabel> {fileAttr.type + " ; "+ fileAttr.w+"x"+fileAttr.h +"px ; "+  getFileSize()} </FileFormatlabel>
          :
            <FileFormatlabel> {fileAttr.type+ " ; "+  getFileSize()} </FileFormatlabel>
          }
        </FileFormat>
      :""}
    </div>
    )
  }

  const renderDropImage = () => {
    return _renderDropFile(FILETYPE_IMAGE, null);
  }

  const renderDropVoice = () => {
    return _renderDropFile(FILETYPE_VOICE, null);
  }

  const renderDropFile = () => {
    return _renderDropFile(FILETYPE_UNKNOWN, null);   // possible todo here
  }

  const renderDropZone = () => {
    return (
      <DropZone 
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >

        {selectedFile || useProfilePicture ? (
          < >
            {otherProps.fileType===FILETYPE_IMAGE ? 
              renderDropImage()
            :
            otherProps.fileType===FILETYPE_VOICE ? 
            renderDropVoice()
            :
            renderDropFile()
            }
          </>  
          ) : (

            <DropInside 
            >
              <DropIcon
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
                role="button"
                data-link={"idUploadSpan_"+otherProps.id}
                onClick = {onOpenSelect}
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </DropIcon>

              <FileHidden  
                className="ImagePreview hidden" 
                onClick={onImageClick}
                alt="to receive the selected file"
                ref={uploadedFile}
              />
              
              <div >
                <label
                  role="button"                      
                  htmlFor={"file-upload_"+otherProps.id}
                  className=" rounded-md bg-white text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span 
                    id = {"idUploadSpan_"+otherProps.id}
                  >Upload a file</span>
                  <FileInput id={"file-upload_"+otherProps.id} name={"file-upload_"+otherProps.id} type="file" onChange={onSelectFile} />
                </label>
                <p className="pl-1 cursor-default">
                  or drag and drop
                </p>
                {otherProps.fileType==FILETYPE_VOICE? 
                  <p className="pl-1 cursor-default">or record live</p> 
                : ""}

              </div>
              <FileFormat>{getAcceptedFormat()+" up to "+getMaxFileSize()}</FileFormat>

              {otherProps.fileType==FILETYPE_IMAGE && otherProps.user!==null ? 
                  <BtnMobile 
                    id = {"idProfileBtn_"+otherProps.id}
                    className="right-[2px] top-0"
                    onClick={() => {
                      onChooseFromProfile(otherProps.id)                    
                      }
                    }
                    title="Upload a profile image"
                  >
                  <UserIcon  id = {"idProfile_"+otherProps.id} />
                  </BtnMobile>              
              :""}
              
              {otherProps.fileType==FILETYPE_VOICE? 
              <BtnMobile 
                id = {"idRecordBtn_"+otherProps.id}
                className="right-[2px] bottom-[2px]"
                onClick={() => {
                    onStartRecordingVoice(otherProps.id)                    
                  }
                }
              >
                <MicIcon  id = {"idMic_"+otherProps.id} />
                <span  id = {"idCountdown_"+otherProps.id} />
              </BtnMobile>
              :""}

          </DropInside>
          )}

    </DropZone>
    );
  }

  return (
    <Section 
      className={otherProps.isHidden ? "hidden" : ""}
      style = {BoxStyle}
    >
      <SectionTitle>{otherProps.title}</SectionTitle>

      {renderDropZone()}

      <DialogZoom
        isVisible={isZoomDialogOpen}
        hasCrop={true}
        cropWidth={Math.min(Math.min(cDefaultPictureSize, fileAttr.w), fileAttr.h)}
        cropHeight={Math.min(Math.min(cDefaultPictureSize, fileAttr.w), fileAttr.h)}
        aspect= {1}
        onClose={onCloseZoom}
        onCrop={async_onCrop}
        filename={fileAttr? fileAttr.filename: null}
        image={fileAttr? fileAttr.srcOriginal: null}
        defaultW = {fileAttr? fileAttr.w: cDefaultPictureSize}
        defaultH = {fileAttr? fileAttr.h: cDefaultPictureSize}
      />

      <UiFileSelectProfile 
        isVisible={isSelectProfileOpen}
        onSelect = {onSelectProfile}
        user = {otherProps.user}
      />

    </Section>
  );
};
