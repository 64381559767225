import tw from "twin.macro";
import {Section, SectionTitle} from "./uiStyles";

/* css */
const Input = tw.input`w-full px-4 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;

export const UiEdit = (props) => {
  
  /* 
  *   UI 
  */

  return(
    <Section>
      <SectionTitle>{props.title}</SectionTitle>
      <Input 
        type="text" 
        placeholder={props.placeholder}
        value={props.value.default} 
        onChange={props.onChange}
      /> 
    </Section>
  );
}

