import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {SnapshotAllAIs} from "components/ai/aiSnapshot.js";
import tw from "twin.macro";

import {setSEO_title, setSEO_description } from "services/seo";

const Heading = tw.h2`text-2xl xl:text-3xl  text-gray-900 mb-12`;

let wasSetSEO=false;
export default (props) => {

  useEffect(() => {
    if(!wasSetSEO) {
      wasSetSEO=true;
      document.title = 'OSAIS - List of AIs';
      setSEO_title('OSAIS - List of AIs');
      setSEO_description("List of all AIs available on your own GPU via OpenSourceAIs");
    }
  }, []);

  return (
  <AnimationRevealPage>
    <Header 
        user = {props.user}
    />

    <Heading>Pick your AI</Heading>

    <SnapshotAllAIs 
      aAIAgent = {props.aEngine}
    />
    <Footer />
  </AnimationRevealPage>
)};
