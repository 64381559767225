

/*
 *    SEO apis
 */

const setSEO_title = (_value) => {
  document.title = _value;
  document.getElementById('meta_og_title').setAttribute("content", _value);
  document.getElementById('meta_twitter_title').setAttribute("content", _value);
}

const setSEO_description = (_value) => {
  document.getElementById('meta_description').setAttribute("content", _value);
  document.getElementById('meta_og_description').setAttribute("content", _value);
  document.getElementById('meta_twitter_description').setAttribute("content", _value);
}

const setSEO_type = (_value) => {
  document.getElementById('meta_og_type').setAttribute("content", _value);
}

const setSEO_image = (_value) => {
  document.getElementById('meta_og_image').setAttribute("content", _value);
  document.getElementById('meta_twitter_image').setAttribute("content", _value);
}

export { 
  setSEO_title,
  setSEO_description,
  setSEO_type,
  setSEO_image
}