import React, { useState, useEffect } from "react";
import tw from "twin.macro";

import { MyRubik } from './myRubik'; 

const ContainerWithBorder = tw.div `sm:rounded-lg  `;
const Content = tw.div`max-w-screen-xl bg-white text-gray-900 shadow-none sm:shadow flex justify-center flex-1 max-md:block  `;
const MainContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-6 lg:w-1/2 xl:w-5/12   `;
const MainContent = tw.div`mt-2 flex flex-col  `;
const SecContainer = tw.div`w-full md:mt-[40%]`;

export const SectionWarmup = (props) => {


  return (
    <ContainerWithBorder>
    {props.isVisible? 

        <Content>
          <MainContainer >

            <MainContent>
              <MyRubik />
            </MainContent>
          </MainContainer>

          <MainContainer >
            <MainContent>
                <SecContainer>
                  AWAITING AI Agent to initialize...
                </SecContainer>
            </MainContent>
          </MainContainer>

        </Content>     
    :""}
    </ContainerWithBorder>
  );
}