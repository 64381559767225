import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import * as te from 'tw-elements';

Modal.setAppElement("#root");
window.isDebug = (window.location.hostname==="localhost")

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);