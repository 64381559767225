import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

import StableDiffImage from "../../images/Stability_AI.gif";
import {SnapshotAllAIs} from "components/ai/aiSnapshot.js";

const Container = tw.div`relative`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.p`w-full text-center `;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
  .button {
    ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none `}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const aFeatured=["cnet", "vtoonify", "coquitts", "gfpgan", "sadtalker"]
export default (props) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const [aAIAgent, setAAIAgent] = useState([]);

  useEffect(() => {
    // get the features ones
    let _aTmp=[];
    if(props.aAIAgent && props.aAIAgent.length>0 && aAIAgent.length===0) {
      for (let  i=0; i<aFeatured.length; i++) {
        const featured=aFeatured[i];
        let k=props.aAIAgent.findIndex(function (x) {
          return (x.engine===featured)
        });
        if(k!==-1) {
          _aTmp.push(props.aAIAgent[k])
        }
      }
      setAAIAgent(_aTmp);
    }

  }, [props.aAIAgent, aAIAgent])

  return (
    <Container id="featured">
      <ThreeColumnContainer>
        {props.subheading && <Subheading>{props.subheading}</Subheading>}
        <Heading>{props.heading}</Heading>
        {props.description && <Description>{props.description}</Description>}
        <VerticalSpacer />

        <SnapshotAllAIs
          aAIAgent={aAIAgent}
        />
        <VerticalSpacer />

        <Description>This is only a subset of all available AIs</Description>
        <PrimaryButton buttonRounded={true} as="a" href="/WorldOfAIs/list">
          Check-out all available AIs
        </PrimaryButton>

      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
