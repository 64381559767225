import React, { useState, useEffect } from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SingleCol.js";

import {setSEO_title, setSEO_description } from "services/seo";

let wasSetSEO=false;

export default (props) => {

  useEffect(() => {
    if(!wasSetSEO) {
      wasSetSEO=true;
      document.title = 'OSAIS - Pricing';
      setSEO_title("Pricing at OpenSourceAIs - OSAIS");
      setSEO_description("List of prices on offer at OpenSourceAIs");
    }
  }, []);

  return (
    <AnimationRevealPage>
      <Header 
        user = {props.user}
      />
      <Pricing />
      <Testimonial
        heading="Our Paying Customers"
      />
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
  );
};
