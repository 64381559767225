import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import FormAI from "components/ai/aiForm.js";
import {setSEO_title, setSEO_description, setSEO_type } from "services/seo";
import {srv_getAIConfig, srv_getAIAgent} from "services/osais";

let _lastEngine=null;

export default (props) => {

  const {engine} = useParams();
  const [aiConfig, setAiConfig] = useState(null);                       // this AI config
  const [aiAgent, setAIAgent] = useState(null);                         // this AI Agent (incl locations)

  useEffect(() => {
    if(engine!=_lastEngine && props.aAIAgent) {
      _lastEngine=engine;
      setSEO_type("article");
      setSEO_title('OSAIS - AI '+engine);
      setSEO_description("Access AI "+engine+" on your own GPU via OpenSourceAIs");             
    }  
    
    // load config
    if(aiConfig===null) {
      async_loadConfig();
    }

    // load agent info
    if(aiAgent===null) {
      async_loadAIAgent();
    }
  });

/* 
*    Init client for processing requests (demo client, or User default client)
*/  

  const async_loadConfig = () => {
    if (engine) {
      srv_getAIConfig(engine)
      .then(data => {
        setAiConfig(data.data);
      })
      .catch(err => {})
    }
  }

  const async_loadAIAgent = () => {    
    if(engine) {
      srv_getAIAgent(engine)
      .then(data => {
        setAIAgent(data.data);
      })
      .catch(err => {})
    }
  }

  return (
  <AnimationRevealPage>
    <Header 
        user = {props.user}
    />    

    {aiConfig!=null? 
      <FormAI 
        ai_engine = {engine}
        ai_config = {aiConfig}
        ai_agent = {aiAgent}
        version = "0.0.0"
        user = {props.user}
        isPublic = {props.isPublic===true}
        isMobile = {props.isMobile}    
        panel = {props.panel}    
        reloadAIAgents = {async_loadAIAgent}
      />
      :""}
    <Footer />
  </AnimationRevealPage>
)};
