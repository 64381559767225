import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "feather-icons/dist/icons/arrow-left-circle.svg";
import { ReactComponent as SendIcon } from "feather-icons/dist/icons/send.svg";
import { ReactComponent as ShareIcon } from "feather-icons/dist/icons/share.svg";
import { MyCarousel } from './myCarousel'; 
import { MyMusicPlayer } from './myMusicPlayer'; 
import { MyVideoPlayer } from './myVideoPlayer'; 
import { MyRubik } from './myRubik'; 
import { SectionPrivateInput } from './aiSectionInput'; 


/* css */
const DivPropval=styled.div `${tw`relative mb-0 mt-0 flex w-full ` }`;
const DivProp=styled.div `${tw` text-xs text-gray-600 font-bold mb-0 mt-0 inline-flex min-w-[80px]` }`;
const DivVal=styled.div  `${tw` text-xs text-gray-900 inline-flex ` }`;

const SmallPrint=styled.p `${tw` mt-6 text-xs text-gray-600 text-center` }`;
const SmallPrintLarger=styled.p `${tw`mt-8 text-sm text-gray-600 text-center` }`;
const SmallPrintLink=styled.a `${tw`border-b border-gray-500 border-dotted` }`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none  `}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const SubmitButtonHalf = tw(SubmitButton) `${`max-w-[46%] inline-flex mx-1`}`;

const ContainerWithBorder = tw.div `sm:rounded-lg  `;
const Content = tw.div`max-w-screen-xl bg-white text-gray-900 shadow-none sm:shadow flex justify-center flex-1 max-md:block  `;

const Relative= tw.div`${`relative block w-full text-center`}`;
const MainContainer = tw.div` py-12 px-2 sm:px-8 max-md:py-2 lg:w-1/2 xl:w-5/12   `;
const MainContent = tw.div`mt-2 flex flex-col items-center`;
const StageLabel=styled.div `${tw` w-full mt-1 font-bold text-center text-gray-600 `}`;
const ProgressCont = styled.div `${tw` flex bg-[#00000012] overflow-hidden h-3 w-full font-sans rounded-full text-xs font-medium `}`;
const ProgressBar = styled.div `${tw` flex justify-center items-center h-full overflow-hidden break-all rounded-full bg-indigo-500 text-white `}`;

const cAIProgress={
  AI_PROGRESS_NO_REQ_FOUND: -10,
  AI_PROGRESS_NO_AI_FOUND: -4,
  AI_PROGRESS_BADLY_FORMATTED_REQUEST: -3,
  AI_PROGRESS_AI_NOT_AVAILABLE: -2,
  AI_PROGRESS_ERROR: -1,
  AI_PROGRESS_REQSENT: 0,
  AI_PROGRESS_REQRECEIVED: 1,
  AI_PROGRESS_START_AI: 2,
  AI_PROGRESS_PROCESSING: 3,
  AI_PROGRESS_DONE_IMAGE: 4,
  AI_PROGRESS_STOP_AI: 5,
  AI_PROGRESS_REQFINISHED: 6,
}

let pcProgress=0;
let _timer=null;

export const SectionPublicResult = (props) => {

  const [aAIConfig, setAAIConfig] = useState(null);                       // this AI config
  const [aPropVal, setAPropVal] = useState([]);                           // all Property / value from this AI config
  const [generateCaptionPart, setGenerateCaptionPart] = useState("re-generate");     // button part text
  const [stageAsText, setStageAsText] = useState("");
  const [aiEngine, setAiEngine] = useState(null);              // the engine name
  const [ext, setExt] = useState("");

  // receiving the request as it is processed
  useEffect(() => {
    if(props.request) {
      document.title="OSAIS - AI "+props.request.engine;
      let _qtyExpect = props.request.qty? props.request.qty : 1;
      if(aAIConfig && 
        (props.request.stage===cAIProgress.AI_PROGRESS_REQFINISHED || 
          props.request.stage===cAIProgress.AI_PROGRESS_STOP_AI && props.request.response && props.request.response.length>=_qtyExpect) 
      ) {
        setAPropVal(getVisiblePropVal(aAIConfig));
        setGenerateCaptionPart(getRegenerateCaption(aAIConfig));
      }
      }
  }, [props.request])

  useEffect(() => {
    refreshStageOfAI(props.stage);
  }, [props.stage])

  useEffect(() => {
    if(ext=="" && props.aImage.length>0) {
      let _ext=props.aImage[0].url.substring(props.aImage[0].url.lastIndexOf("."), props.aImage[0].url.length).toUpperCase();
      setExt(_ext);
    }
  }, [props.aImage])

  useEffect(() => {
    if(props.ai_config!==null) {
      setAiEngine(props.ai_config.engine);
      setAAIConfig(props.ai_config.aParam);
      setAPropVal(getVisiblePropVal(props.ai_config.aParam));
      setGenerateCaptionPart(getRegenerateCaption(props.ai_config.aParam));
    }
  },[props.ai_config])

  /* 
  *   UI 
  */

  const refreshProgress = (_val) => {
    pcProgress=_val;
    const element = document.getElementById('dynamic-progress');
    if(element && element.style) {
      element.style.setProperty('--percentage', pcProgress);
    }
  }

  const getVisiblePropVal = (_aConfig) => {
    let _aRet=[];
    if(_aConfig) {
      _aConfig.forEach(item => {
        if(item.hasOwnProperty("ui") && props.request[item.in]) {
          _aRet.push({
            property: item.in,
            value: props.request[item.in],
            unit: item.ui.unit? item.ui.unit: ""
          })
        }
      })  
    }
    return _aRet;
  }

  const getRegenerateCaption = (_aConfig)=> {
    // loop all widgets and add a regen button if we have a random effect
    let caption=null;
    _aConfig.forEach(item => {
      if(item && item.type==="function" && item.value && item.value.default==="$random") {
        if(!caption) {
          caption = item.in;
        }
        else {
          caption = caption + ", "+item.in;
        }
      }
    })
    return (caption===null? "re-generate" : "re-generate (random "+caption+")")
  }
  
  const refreshStageOfAI = function(_stage) {
    switch(_stage) {
      default:
      case cAIProgress.AI_PROGRESS_REQSENT: 
        refreshProgress(4);
        setStageAsText("Initiated request, looking for an AI...")
        break;
      case cAIProgress.AI_PROGRESS_REQRECEIVED: 
        refreshProgress(8);
        setStageAsText("AI received your input")
        break;
      case cAIProgress.AI_PROGRESS_START_AI: 
        refreshProgress(10);
        setStageAsText("AI initializing...")
        if(!_timer) {
          _timer=setInterval(() => {
            // todo : later, we could ask the AI its average time to process, and sync the timer with it
            if(pcProgress<33) {
              refreshProgress(pcProgress+1);  // we have 22 ticks (* 200ms = 4s)
            }
            else {
              clearInterval(_timer);
              _timer=null;
            }
          }, 200)      // max move by 20 ticks in 4sec
        }
        break;
      case cAIProgress.AI_PROGRESS_PROCESSING: 
        setStageAsText("AI is processing...")
        if(_timer) {clearInterval(_timer);}
        _timer=setInterval(() => {
          // todo : later, we could ask the AI its average time to process, and sync the timer with it
          if(pcProgress<93) {
            refreshProgress(pcProgress+1);
          }
          else {
            clearInterval(_timer);
            _timer=null;
          }
        }, props.expTimeInMs? props.expTimeInMs/60: 200)  // max move by 60 ticks in x Sec
        break;
      case cAIProgress.AI_PROGRESS_DONE_IMAGE: 
        refreshProgress(95);
        setStageAsText("AI has generated a "+ext)
        break;
      case cAIProgress.AI_PROGRESS_STOP_AI:
        refreshProgress(95);
        setStageAsText("AI has finished its job")
        break;
      case cAIProgress.AI_PROGRESS_REQFINISHED: 
        refreshProgress(100);
        setStageAsText("This request was processed")
        break;
      case cAIProgress.AI_PROGRESS_AI_NOT_AVAILABLE:
        refreshProgress(100);
        setStageAsText("Error - AI failed to process this request - looking for another one...")
        break;
      case cAIProgress.AI_PROGRESS_BADLY_FORMATTED_REQUEST:
      case cAIProgress.AI_PROGRESS_ERROR:
        refreshProgress(100);
        setStageAsText("Error - AI returned in error - Fatal!")
        break;
      case  cAIProgress.AI_PROGRESS_NO_REQ_FOUND:
        refreshProgress(100);
        setStageAsText("Error - Never processed such request")
        break;
      case cAIProgress.AI_PROGRESS_NO_AI_FOUND:
        refreshProgress(100);
        setStageAsText("Error - No AI available to process this request")
        break;
      }
  }

  const _getDisplayValue = (item) => {
    let _unit=item.unit? item.unit :""
    let _strVal=item.value===true? "true" : null;
    if(_strVal===null) {
      _strVal=item.value===false? "false" : item.value;
    }
    return _strVal+_unit;
  }

  return (
    <ContainerWithBorder>
      <Content>
        <MainContainer>

          <MainContent>
              <StageLabel
                className={props.stage<0? "red": ""}
              >
                {props.isPublic && props.cost_in_ms && props.aImage? props.aImage.length+" "+ext+" generated via OpenSourceAIs in "+(Math.floor(props.cost_in_ms/100)/10)+" sec" : stageAsText}
              </StageLabel>

              {props.hasFinished===false?
                <ProgressCont >
                  <ProgressBar 
                    id="dynamic-progress" 
                    className="dynamic-width" 
                  />
                </ProgressCont>
              :""}

              <Relative>
                <SubmitButtonHalf
                  onClick={( )=> {props.onClickBack(aiEngine)}}
                  >
                    <BackIcon className="icon" />
                    <span className="text">{props.backButtonText}</span>
                </SubmitButtonHalf>

                <SubmitButtonHalf
                  onClick={props.onClickShare}
                  >
                    <ShareIcon className="icon" />
                    <span className="text">{props.shareButtonText}</span>
                </SubmitButtonHalf>
              </Relative>

              <br />
                {props.stage<0? 
                    <img src= "/assets/clown.jpg" />                
                  :
                  <>
                  {props.aImage.length===0 ? 
                    <MyRubik />
/*                    <img src= "/assets/please_wait.png" />  */
                    : 
                    <>
                      {ext===".PNG" || ext===".JPG" || ext===".JPEG" ?
                        <MyCarousel 
                          aImage = {props.aImage}
                        />
                      : ""
                      }
  
                      {ext===".WAV" || ext===".MP3" || ext===".WMA" ?
                        <MyMusicPlayer 
                          aFile = {props.aImage}
                        />
                      : ""
                      }
  
                      {ext===".MP4" ?
                        <MyVideoPlayer 
                          aFile = {props.aImage}
                        />
                      : ""
                      }
  
                    </>
                  }
                  </>
                }

                <br />
                {aPropVal && aPropVal.length>0 ? 
                  aPropVal.map((item, i) => (
                    (item.value && item.value!=="") ?
                      <DivPropval
                        key = {i}
                      >
                        <DivProp>{item.property}</DivProp>
                        <DivVal>{_getDisplayValue(item)}</DivVal>
                      </DivPropval>                        
                    :""
                  ))
                : 
                  ""
                }

                {props.hasFinished && props.hasVariableInput ? 
                  <SubmitButton
                    onClick={props.onClickGenerate}
                    >
                      <SendIcon className="icon" />
                      <span className="text">{generateCaptionPart}</span>
                  </SubmitButton>

                :""}
                
                {props.user===null ? 
                        <SmallPrintLarger >
                          You are using a Demo account
                          <br />
                          <SmallPrintLink href="/app">
                            Sign In 
                          </SmallPrintLink>
                          <span> to avoid limitations</span>
                        </SmallPrintLarger>
                        :
                        ""
                      }

          </MainContent>
        </MainContainer>

      </Content>
    </ContainerWithBorder>

  );

}