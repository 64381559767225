
let  root="https://opensourceais.com"
if(window.location.port==="3000" || window.location.port==="3022") {
  root="http://localhost:3022"
}

let _userToken=null;
// in DEBUG ONLY and for port 3000 only, for 3022 / prod cookie will do it
if(window.location.port==="3000") {
  // basic user 
  _userToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImU0M2E4YjNkNDdjNWM2YWRmZGIwZjY2ODcyNDUxZDY2YWIyZDFkOWYzMGY5YWUzMjZjMmMyNzgxZGZjODhiYzAiLCJpZGVudGl0eV9wcm92aWRlciI6IlNJV1AiLCJ3YWxsZXQiOiJQaGFudG9tIiwiYWRkcmVzcyI6IjR4Y29YUTFnZHY4cnp1cUJtaHBhb3hXa285b0VyR3hxcldrQndCQkRVZWptIiwiZmlyc3ROYW1lIjoiQW5vbiIsInRva2VuIjpudWxsLCJpYXQiOjE2ODg0NTg3MjAsImV4cCI6MTY4ODcxNzkyMH0.JFah6NY4IB8S_KOrV9DkTWWXyThlBcDYp-L7s3lDvw0";

  // super user 
  _userToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IjNmYmU1M2NiYTE4YTVjNzNjM2I2OTQyMWU0ZjQ0ODEyNDYwYzJlNTViNzYzNGE3NzAwNmU1NGUzZjU2MDVhM2IiLCJpZGVudGl0eV9wcm92aWRlciI6IlNJV0siLCJ3YWxsZXQiOiJLZXBsciIsImFkZHJlc3MiOiJjb3Ntb3MxOTJjdjZheGNteTI5YWswYTBwMDg0cDd0czU0bTU3MmhlcnJ5OGMiLCJmaXJzdE5hbWUiOiJBbm9uIiwiZW1haWwiOiIzZmJlNTNjYmExOGE1YzczYzNiNjk0MjFlNGY0NDgxMjQ2MGMyZTU1Yjc2MzRhNzcwMDZlNTRlM2Y1NjA1YTNiQG9zYWlzLmNvbSIsInRva2VuIjpudWxsLCJpYXQiOjE3MjU1NDA5OTQsImV4cCI6MTcyNTgwMDE5NH0.2eNym3oUXI50HrBtmz4x2lp3OIM9UWLBy-5_3whdB24";

  // subscribed user
//  _userToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImU0M2E4YjNkNDdjNWM2YWRmZGIwZjY2ODcyNDUxZDY2YWIyZDFkOWYzMGY5YWUzMjZjMmMyNzgxZGZjODhiYzAiLCJpZGVudGl0eV9wcm92aWRlciI6IlNJV1AiLCJ3YWxsZXQiOiJQaGFudG9tIiwiYWRkcmVzcyI6IjR4Y29YUTFnZHY4cnp1cUJtaHBhb3hXa285b0VyR3hxcldrQndCQkRVZWptIiwiZmlyc3ROYW1lIjoiQW5vbiIsInRva2VuIjpudWxsLCJpYXQiOjE2ODYxMjY3NjMsImV4cCI6MTY4NjM4NTk2M30.drvSsJ791LkoUcTMlfoCaxsrN74LsXySze53fOomEbk";
}

const API_ROUTE= root+"/api/v1/";
const API_PUBLICROUTE= root+"/api/v1/public/";
const API_PRIVATEROUTE= root+"/api/v1/private/";


const srv_getRoute = async(route, _token) => {
  try {
    let isPrivate=route.includes(API_PRIVATEROUTE);
    let _query={
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    }

    // for localhost:3000 (debug) we do this
    if(_token===null || _token===undefined) {
      _token = _userToken;
    }
    if(isPrivate && _token) {
      _query.headers["Authorization"]="Bearer " + (_token? _token : "")
    }
    const response = await fetch(route, _query);
    const json = await response.json();
    return json;
  } catch(error) {
    console.log("Error GET "+ error? error : "");
    return {data: null};
  }
}

const srv_getUniqueRoute = async(route, _token) => {
  try {
    let ts=new Date().getTime();
    let _route=route+"?ts="+ts
    return srv_getRoute(_route, _token);
  } catch(error) {
    console.log("Error GET Unique "+ error? error : "");
    return {data: null};
  }
}

const srv_patchRoute = async (route, data, _token) => {
  return _srv_pRoute('PATCH', route, data, _token);
}

const srv_postRoute = async (route, data, _token) => {
  return _srv_pRoute('POST', route, data, _token);
}

const _srv_pRoute = async (verb, route, data, _token) => {
  try {
    let isPrivate=route.includes(API_PRIVATEROUTE);
    let jsonStr=data? JSON.stringify(data): null;
    let _query={
      method: verb,
      headers: {'Content-Type': 'application/json'},
    }
    // for localhost:3000 (debug) we do this
    if(_token===null || _token===undefined) {
      _token = _userToken;
    }
    if(isPrivate && _token) {
      _query.headers["Authorization"]="Bearer " + (_token? _token : "")
    }
    if(jsonStr) {
      _query.body=jsonStr;
    }

    const response = await fetch(route, _query);
    const json = await response.json();
    return json;
  } catch(error) {
    console.log(error);
    return {data: null};
  }
}


const srv_deleteRoute = async (route) => {
  try {
    const response = await fetch(route, {
        method: 'DELETE'
      }
    );
    if(response.status!==204) {
      return response;
    }
    return {data: null};
  } catch(error) {
    console.log(error);
    return {data: null};
  }
}

export { 
  root,
  API_ROUTE,
  API_PRIVATEROUTE,
  API_PUBLICROUTE,
  srv_getRoute, 
  srv_getUniqueRoute,
  srv_patchRoute,
  srv_postRoute,
  srv_deleteRoute,
}