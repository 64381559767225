import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Section, SectionTitle, HelpTooltip, HelpIconStyle, DisabledStyle} from "./uiStyles";

/* css */
const TogGroup=styled.div `${tw`relative inline-block justify-center space-x-3 my-2` }`;
const TogBtn=styled.button `${tw`relative inline-flex justify-center rounded-full bg-gray-200 text-gray-600 px-2 my-1 min-w-[40px]` }`;
const TogBtnSel=styled.button `${tw`relative inline-flex justify-center rounded-full bg-blue-500 text-white px-2 my-1 min-w-[40px]` }`;

export const UiMultiToggle = (props) => {
  
  /* 
  *   UI 
  */

  function ToggleButton({ label, active, disabled, onClick }) {
    if(active) {
      return (
        <TogBtnSel
          onClick={onClick}
          type="button"
          style = {disabled? DisabledStyle: {}}
        >
          {label}
        </TogBtnSel>
      );  
    }
    else {
      return (
        <TogBtn
          onClick={onClick}
          type="button"
          style = {disabled? DisabledStyle: {}}
        >
          {label}
        </TogBtn>
      );
    }
  }

  function ToggleButtonGroup({ options, selected, onChange }) {
    return (
      <TogGroup >
        {options.map(option => (
          <ToggleButton
            key={option.value}
            label={option.text}
            active={option.value === selected}
            disabled={option.isDisabled}
            onClick={() => onChange(option.value)}
          />
        ))}
      </TogGroup>
    );
  }

  return(
    <Section>
      <SectionTitle>{props.title}</SectionTitle>
      <ToggleButtonGroup
        options={props.aOption}
        selected={props.selected}
        onChange={props.onChange}
      />
    </Section>
  );
}

