import { useState } from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "../../images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/dot-pattern.svg"
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";

import Register from "components/cta/Register.js";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-8 max-w-screen-2xl mx-auto sm:px-8`;
const NavRow = tw.div`flex flex-col lg:flex-row justify-between items-center`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5   hidden `;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
const Quote = tw.blockquote``
const CustomerName = tw.p`mt-4 font-bold`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`
const UpdateNotice = tw(Column)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const defaultParams={
  heading : "Free Open Source AIs on your own GPU",
  description : "Access mainstream Open Source AIs via our UI or directly from your app, with a simple API integration. Tap into a whole range of low cost processing power, including your own GPU.",
  imageSrc : "/static/media/team-illustration-2.76aa0427e037826d4453f8791ee544e3.svg",
  videoSrc : "/assets/statue.mp4",
  imageDecoratorBlob : true,
  primaryButtonUrl : "https://docs.google.com/forms/d/1_awE_xii_dIv6EHtrWFbUV3eqE10m7L6MjL0Ze6dmq0",
  primaryButtonText : "Get into Wait List...",
  buttonRounded : true,
  features : ["Run AIs for free on your own GPU", "Upload your own models", "Earn by sharing access to your GPU or models", "Keep AI output data private", "99.99% Uptime SLA"],
  testimonial : {
    quote: " You are only limited by the boundaries of your imagination...",
    customerName: "Built with Open Source AIs",
    customerCompany: "Credits @TomLikesRobots"
  }
}
export default (props) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [wasSentOK, setWasSentOK] = useState(false);
  const [wasSentError, setWasSentError] = useState(true);
  
  const validateEmail = (email) => {
    // Simple regex validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email); 
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValid(validateEmail(e.target.value));
  }

  const handleOnClick = (e) => {
    if(isValid) {

    }
  }

  const buttonRoundedCss = defaultParams.buttonRounded && tw`rounded-full`;
  return (
    <>
      <Header 
        user = {props.user}
      />
      <Container>
        <ContentWithVerticalPadding>
          <NavRow>
            <UpdateNotice>
              <UpdateNoticeIcon />
              <b>Beta release</b>&nbsp;&nbsp; Get into the Wait List for free test API access. &nbsp;&nbsp; <b>Stop paying bills to Cloud providers!</b>
            </UpdateNotice>
          </NavRow>

          <Row>
            <TextColumn>
              <Heading>{defaultParams.heading}</Heading>
              <Description>{defaultParams.description}</Description>

              <Register 
                center = {false}
              />
              
              <PrimaryButton as="a" href={defaultParams.primaryButtonUrl} css={buttonRoundedCss} >
                {defaultParams.primaryButtonText}
              </PrimaryButton>
              <FeatureList>
                {defaultParams.features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
              <video width="320" height="480" loop autoPlay muted playsInline >
                <source src={defaultParams.videoSrc} type="video/mp4" />
              </video>

                {defaultParams.imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <Quote>{defaultParams.testimonial.quote}</Quote>
                  <CustomerName>{defaultParams.testimonial.customerName}</CustomerName>
                  <CustomerCompany>{defaultParams.testimonial.customerCompany}</CustomerCompany>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
